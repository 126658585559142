@font-face {
  font-family: 'strassacker-edition';
  font-weight: normal;
  font-style: normal;
  src: url("assets/fonts/strassacker-edition.woff") format("woff"), url("assets/fonts/strassacker-edition.svg#strassacker-edition") format("svg"); }

[data-icon]:before {
  font-family: 'strassacker-edition' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  line-height: 1;
  text-transform: none !important;
  content: attr(data-icon);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^='ico-']:before,
[class*=' ico-']:before {
  font-family: 'strassacker-edition' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  line-height: 1;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ico-merkzettel:before {
  content: "\61"; }

.ico-suche:before, .m-artist-list__item > div:before, .m-search-input:before {
  content: "\62"; }

.ico-warenkorb:before {
  content: "\63"; }

.ico-close:before {
  content: "\64"; }

.ico-arrow-left:before {
  content: "\65"; }

.ico-arrow-right:before, .m-breadcrumbs > li:before {
  content: "\66"; }

.ico-zeitspanne:before {
  content: "\67"; }

.ico-arrow-up:before {
  content: "\68"; }

.ico-arrow-down:before {
  content: "\69"; }

.ico-social:before {
  content: "\6a"; }

.ico-minus:before {
  content: "\6b"; }

.ico-plus:before {
  content: "\6c"; }

.ico-grid-4:before {
  content: "\6d"; }

.ico-diamond:before {
  content: "\6e"; }

.ico-refund:before {
  content: "\6f"; }

.ico-grid-3:before {
  content: "\70"; }

.ico-fingerprint:before {
  content: "\71"; }

.ico-truck:before {
  content: "\72"; }

.ico-star:before {
  content: "\73"; }

.ico-konto:before {
  content: "\74"; }

.ico-burger:before {
  content: "\75"; }

.ico-rotate-right:before {
  content: "\76"; }

.ico-rotate-left:before {
  content: "\77"; }

.ico-play:before {
  content: "\78"; }

.ico-pause:before {
  content: "\79"; }

.ico-filter:before {
  content: "\7a"; }

.ico-check:before, .m-checkbox:checked + label:before, .m-progress-bar > ol > li.done:before, .l-cart-panel__message:before {
  content: "\41"; }

.ico-phone:before {
  content: "\43"; }

.ico-gplus:before {
  content: "\44"; }

.ico-facebook:before {
  content: "\45"; }

.ico-twitter:before {
  content: "\46"; }

.ico-info:before, .m-form__group > aside:before {
  content: "\47"; }

.ico-error:before {
  content: "\42"; }

.ico-eye:before {
  content: "\48"; }

.ico-mail:before {
  content: "\49"; }

.ico-lock:before {
  content: "\4a"; }

.ico-spinner:before {
  content: "\4b"; }

.ico-label:before {
  content: "\4c"; }

.ico-zeitspanne-2:before {
  content: "\4d"; }

.ico-pinterest:before {
  content: "\4e"; }

.ico-edit:before {
  content: "\4f"; }

.ico-globe:before {
  content: "\50"; }

.ico-calendar-30:before {
  content: "\51"; }

.ico-euro:before {
  content: "\52"; }

@font-face {
  font-family: 'Frutiger';
  font-weight: 300;
  font-style: normal;
  src: url("assets/fonts/Frutiger-Light.woff") format("woff"), url("assets/fonts/Frutiger-Light.svg") format("svg"); }

@font-face {
  font-family: 'Frutiger';
  font-weight: 300;
  font-style: italic;
  src: url("assets/fonts/Frutiger-Light-Italic.woff") format("woff"), url("assets/fonts/Frutiger-Light-Italic.svg") format("svg"); }

@font-face {
  font-family: 'Frutiger';
  font-weight: 400;
  font-style: normal;
  src: url("assets/fonts/Frutiger.woff") format("woff"), url("assets/fonts/Frutiger.svg") format("svg"); }

@font-face {
  font-family: 'Frutiger';
  font-weight: 400;
  font-style: italic;
  src: url("assets/fonts/Frutiger-Italic.woff") format("woff"), url("assets/fonts/Frutiger-Italic.svg") format("svg"); }

@font-face {
  font-family: 'Frutiger';
  font-weight: 600;
  font-style: normal;
  src: url("assets/fonts/Frutiger-Bold.woff") format("woff"), url("assets/fonts/Frutiger-Bold.svg") format("svg"); }

@font-face {
  font-family: 'Frutiger';
  font-weight: 600;
  font-style: italic;
  src: url("assets/fonts/Frutiger-Bold-Italic.woff") format("woff"), url("assets/fonts/Frutiger-Bold-Italic.svg") format("svg"); }

.container {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .container:after {
    content: " ";
    display: block;
    clear: both; }

.span--1-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--1-12 {
      width: 8.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-1-12 {
    width: 8.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-1-12 {
    width: 8.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--2-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--2-12 {
      width: 16.6666666667%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-2-12 {
    width: 16.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-2-12 {
    width: 16.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--3-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--3-12 {
      width: 25%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-3-12 {
    width: 25%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-3-12 {
    width: 25%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--4-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--4-12 {
      width: 33.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-4-12 {
    width: 33.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-4-12 {
    width: 33.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--5-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--5-12 {
      width: 41.6666666667%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-5-12 {
    width: 41.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-5-12 {
    width: 41.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--6-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--6-12 {
      width: 50%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-6-12 {
    width: 50%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-6-12 {
    width: 50%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--7-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--7-12 {
      width: 58.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-7-12 {
    width: 58.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-7-12 {
    width: 58.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--8-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--8-12 {
      width: 66.6666666667%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-8-12 {
    width: 66.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-8-12 {
    width: 66.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--9-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--9-12 {
      width: 75%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-9-12 {
    width: 75%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-9-12 {
    width: 75%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--10-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--10-12 {
      width: 83.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-10-12 {
    width: 83.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-10-12 {
    width: 83.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--11-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--11-12 {
      width: 91.6666666667%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-11-12 {
    width: 91.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-11-12 {
    width: 91.6666666667%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.span--12-12 {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 768px) {
    .span--12-12 {
      width: 100%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .span--m-12-12 {
    width: 100%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

@media (min-width: 1024px) {
  .span--l-12-12 {
    width: 100%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.clearfix:after, .l-home__guarantee-teasers:after {
  content: " ";
  display: block;
  clear: both; }

.spacer-xsmall {
  margin-bottom: 0.7rem !important; }

.spacer-small {
  margin-bottom: 1.4rem !important; }

.spacer {
  margin-bottom: 2.8rem !important; }

.spacer-large {
  margin-bottom: 4.2rem !important; }

.spacer-xlarge {
  margin-bottom: 8.4rem !important; }

.text-align-left {
  text-align: left !important; }

.text-align-center {
  text-align: center !important; }

.text-align-right {
  text-align: right !important; }

.text-black {
  color: #646464; }

.float-right {
  float: right; }

/* Base */
#awwwards {
  position: absolute;
  z-index: 5001;
  top: 50px;
  display: none;
  overflow: hidden;
  width: 90px;
  height: 135px;
  text-indent: -666em;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }
  @media (min-width: 1320px) {
    #awwwards {
      display: block; } }

#awwwards.top {
  top: 20px; }

#awwwards.left {
  left: 0; }

#awwwards.right {
  right: 0; }

#awwwards a {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 90px;
  height: 135px;
  background-repeat: no-repeat;
  background-size: 90px 135px; }

/* NOMINEE */
#awwwards.nominee.black.left a {
  background-image: url(assets/images/awwwards/awwwards_nominee_black_left.png); }

#awwwards.nominee.black.right a {
  background-image: url(assets/images/awwwards/awwwards_nominee_black_right.png); }

#awwwards.nominee.silver.left a {
  background-image: url(assets/images/awwwards/awwwards_nominee_silver_left.png); }

#awwwards.nominee.silver.right a {
  background-image: url(assets/images/awwwards/awwwards_nominee_silver_right.png); }

#awwwards.nominee.white.left a {
  background-image: url(assets/images/awwwards/awwwards_nominee_white_left.png); }

#awwwards.nominee.white.right a {
  background-image: url(assets/images/awwwards/awwwards_nominee_white_right.png); }

#awwwards.nominee.green.left a {
  background-image: url(assets/images/awwwards/awwwards_nominee_green_left.png); }

#awwwards.nominee.green.right a {
  background-image: url(assets/images/awwwards/awwwards_nominee_green_right.png); }

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5) {
  /* NOMINEE */
  #awwwards.nominee.black.left a {
    background-image: url(assets/images/awwwards/awwwards_nominee_black_left@2x.png); }
  #awwwards.nominee.black.right a {
    background-image: url(assets/images/awwwards/awwwards_nominee_black_right@2x.png); }
  #awwwards.nominee.silver.left a {
    background-image: url(assets/images/awwwards/awwwards_nominee_silver_left@2x.png); }
  #awwwards.nominee.silver.right a {
    background-image: url(assets/images/awwwards/awwwards_nominee_silver_right@2x.png); }
  #awwwards.nominee.white.left a {
    background-image: url(assets/images/awwwards/awwwards_nominee_white_left@2x.png); }
  #awwwards.nominee.white.right a {
    background-image: url(assets/images/awwwards/awwwards_nominee_white_right@2x.png); }
  #awwwards.nominee.green.left a {
    background-image: url(assets/images/awwwards/awwwards_nominee_green_left@2x.png); }
  #awwwards.nominee.green.right a {
    background-image: url(assets/images/awwwards/awwwards_nominee_green_right@2x.png); } }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
main {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ''; }

a {
  margin: 0;
  padding: 0;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  height: 100%;
  min-height: 100%;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  min-height: 100%;
  color: #646464;
  background: #fff;
  font-family: "Frutiger", sans-serif;
  -webkit-overflow-scrolling: touch; }

.app-container {
  overflow: hidden;
  padding-top: 7.4rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.8rem; }
  @media (min-width: 768px) {
    .app-container {
      font-size: 1.8rem; } }
  @media (min-width: 1024px) {
    .app-container {
      padding-top: 0; } }

.touchevents .app-container {
  padding-top: 7.4rem; }

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important; }

ng\:form {
  display: block; }

.ng-animate-shim {
  visibility: hidden; }

.ng-anchor {
  position: absolute; }

/**
* HEADLINES
*/
h1,
.h1,
h2,
.h2,
h3,
.h3,
.m-form fieldset legend,
h4,
.h4,
h5,
.h5 {
  display: block;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem; }

h1,
.h1 {
  margin-bottom: 1.4rem;
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 4.2rem; }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 3.2rem; } }

h2,
.h2 {
  margin-bottom: 1.4rem;
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 3.22rem; }

h3,
.h3,
.m-form fieldset legend {
  margin-bottom: 1.4rem;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.8rem; }

h4,
.h4 {
  margin-bottom: 1.4rem;
  font-size: 1.6rem;
  line-height: 2.1rem; }

h5,
.h5 {
  margin-bottom: 1.4rem;
  font-size: 1.3rem;
  line-height: 2.1rem; }

.h--underlined {
  position: relative;
  margin-bottom: 2.1rem;
  padding-bottom: 1.4rem; }
  .h--underlined:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 6rem;
    height: 0.2rem;
    background: #D9D1CF;
    content: '';
    transform: translateX(-50%); }

.subheadline {
  font-size: 1.6rem;
  text-align: center; }

/**
* TEXT
*/
strong {
  font-weight: 400; }

small {
  font-size: 1.3rem; }

blockquote {
  position: relative;
  margin-top: 5.6rem;
  margin-bottom: 5.6rem;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  font-size: 2.4rem;
  font-style: italic;
  line-height: 3.5rem;
  text-align: center; }
  blockquote:before, blockquote:after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 6rem;
    height: 0.2rem;
    background: #eaeaea;
    content: '';
    transform: translateX(-50%); }
  blockquote:after {
    top: auto;
    bottom: 0; }
  blockquote.sec:before, blockquote.sec:after {
    background-color: #9a9a9a; }
  blockquote > p:first-of-type:before {
    margin-right: -0.4rem;
    content: '\201C\2009'; }
  blockquote > p:last-of-type:after {
    margin-left: -0.4rem;
    content: '\2009\201D'; }
  blockquote > footer {
    margin-top: -1.4rem;
    font-size: 1.8rem;
    font-style: normal; }
    blockquote > footer:before {
      content: '\2013\00a0'; }

/**
* LINKS
*/
a {
  color: #68c0ba;
  text-decoration: none;
  transition: color 0.3s; }
  a:hover {
    color: #48ada6; }
    a:hover > i {
      color: #48ada6; }

/**
* BUTTONS
*/
button {
  padding: 0;
  border: none;
  background: transparent;
  font-family: "Frutiger", sans-serif;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.3s;
  outline: none; }

.ie9 .h--underlined:after {
  display: none; }

input[type=text],
input[type=password],
input[type=email],
input[type=date],
textarea {
  width: 100%;
  max-width: 100%;
  height: 4.2rem;
  padding: 1.2rem;
  border: 0.1rem solid #d1d1d1;
  background: #fff;
  font-size: 1.5rem;
  transition: border-color 0.3s, corder-width 0.3s, background 0.3s;
  outline: none; }
  input[type=text].ng-touched.ng-invalid:not(.novalidate),
  input[type=password].ng-touched.ng-invalid:not(.novalidate),
  input[type=email].ng-touched.ng-invalid:not(.novalidate),
  input[type=date].ng-touched.ng-invalid:not(.novalidate),
  textarea.ng-touched.ng-invalid:not(.novalidate) {
    border-color: rgba(229, 53, 75, 0.75);
    background: #fceaec; }
  input[type=text].ng-touched.ng-valid:not(.novalidate),
  input[type=password].ng-touched.ng-valid:not(.novalidate),
  input[type=email].ng-touched.ng-valid:not(.novalidate),
  input[type=date].ng-touched.ng-valid:not(.novalidate),
  textarea.ng-touched.ng-valid:not(.novalidate) {
    border-color: rgba(104, 192, 186, 0.75);
    background: #e6f5f4; }

textarea {
  height: 14rem;
  font-family: "Frutiger", sans-serif;
  resize: none; }

.m-abc-nav {
  display: none;
  list-style: none; }
  .m-abc-nav:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .m-abc-nav {
      display: block; } }
  .m-abc-nav > li {
    position: relative;
    float: left;
    display: block;
    overflow: hidden;
    width: 7.6923076923%; }
    @media (min-width: 1024px) {
      .m-abc-nav > li {
        width: 3.8461538462%; } }
    .m-abc-nav > li:before {
      display: block;
      padding-top: 100%;
      content: ''; }
    .m-abc-nav > li > a {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      bottom: 0.2rem;
      left: 0.2rem;
      display: block;
      color: #68c0ba;
      background: #eaeaea;
      font-size: 1.6rem;
      font-weight: 400;
      text-align: center; }
      .m-abc-nav > li > a:hover:not(.disabled) {
        color: #fff;
        background: #68c0ba; }
      .m-abc-nav > li > a.disabled {
        color: #9a9a9a;
        cursor: default; }
      .m-abc-nav > li > a > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }

.m-accordion--sec .m-accordion__item header {
  color: #fff;
  background: #68c0ba; }

.m-accordion__item.active .m-accordion__item-body {
  margin-bottom: 1.4rem; }

.m-accordion__item header {
  position: relative;
  margin-bottom: 1.4rem;
  padding: 1.8rem 4.8rem 1.8rem 6rem;
  color: #68c0ba;
  background: #eaeaea;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.1rem;
  cursor: pointer; }
  .m-accordion__item header > i {
    position: absolute;
    top: 50%;
    left: 2.4rem;
    margin-top: 0.2rem;
    transform: translateY(-50%); }

.m-accordion__item .m-accordion__item-body {
  overflow: hidden;
  transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1), margin-bottom 0.6s cubic-bezier(0.55, 0, 0.1, 1); }

.m-article-teaser {
  background: #fff;
  font-size: 1.6rem;
  text-align: center; }

.m-article-teaser__image {
  overflow: hidden;
  font-size: 0; }
  .m-article-teaser__image > img {
    display: block;
    margin-right: auto;
    margin-left: auto; }

.m-article-teaser__info {
  padding: 2.8rem;
  line-height: 2.1rem; }
  .m-article-teaser__info > strong {
    color: #9a9a9a;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }
  .m-article-teaser__info > h3 {
    line-height: 2.8rem;
    text-transform: none;
    letter-spacing: 0; }

.m-article-teaser--price {
  background-color: #eaeaea;
  position: relative;
  margin-top: 10px; }

.m-artist-list {
  overflow-x: auto;
  margin-right: -1rem;
  margin-left: -1rem;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .m-artist-list {
      white-space: normal;
      max-width: 120rem;
      margin-left: auto;
      margin-right: auto; }
      .m-artist-list:after {
        content: " ";
        display: block;
        clear: both; } }

.m-artist-list__item {
  display: inline-block;
  width: 38%;
  margin-right: 1rem;
  margin-left: 1rem;
  color: #646464;
  white-space: normal;
  vertical-align: top; }
  @media (min-width: 768px) {
    .m-artist-list__item {
      display: block;
      margin-right: 0;
      margin-left: 0;
      width: 16.6666666667%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  @media (min-width: 1024px) {
    .m-artist-list__item:hover > div:before {
      opacity: 1; } }
  .m-artist-list__item > div {
    position: relative;
    font-size: 0;
    line-height: 0; }
    .m-artist-list__item > div:before {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      color: #fff;
      background: rgba(104, 192, 186, 0.5);
      font-family: "strassacker-edition";
      font-size: 4.6rem;
      line-height: 15.4rem;
      text-align: center;
      transition: opacity 0.3s;
      opacity: 0; }
      @media (min-width: 1200px) {
        .m-artist-list__item > div:before {
          line-height: 18.2rem; } }
  .m-artist-list__item > strong {
    display: block;
    height: 4.2rem;
    margin-top: 1.4rem;
    line-height: 1.82rem; }

.m-badge {
  min-width: 1.8rem;
  height: 1.8rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  border-radius: 1.8rem;
  color: #fff;
  background: #db701d;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: center;
  text-indent: 0.1rem; }

.m-box {
  margin-bottom: 1.4rem;
  padding: 1.2rem 1.2rem;
  background: #fff;
  font-size: 1.6rem;
  text-align: center; }
  @media (min-width: 768px) {
    .m-box {
      padding: 1.2rem 1.8rem; } }
  @media (min-width: 1024px) {
    .m-box {
      margin-bottom: 0; } }
  .m-box > a {
    font-weight: 400;
    line-height: 0; }
  .m-box h3 {
    letter-spacing: 0.1rem; }
  .m-box p {
    margin-bottom: 1.4rem;
    line-height: 2.52rem; }
    @media (min-width: 768px) {
      .m-box p {
        line-height: 2.8rem; } }
    .m-box p:last-child {
      margin-bottom: 0; }
  .m-box .h--underlined {
    margin-bottom: 1.4rem; }

.m-box--sliding {
  overflow: hidden; }
  .m-box--sliding > div {
    transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1), opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
    .m-box--sliding > div.slit {
      transform: translateX(-100%);
      opacity: 0; }

.m-box--stretched {
  padding: 2.4rem 0.9rem 1.8rem; }
  @media (min-width: 768px) {
    .m-box--stretched {
      padding: 3.6rem 1.8rem 3rem; } }

.m-box--stretched-xl {
  padding: 3.6rem 0.9rem 3rem; }
  @media (min-width: 768px) {
    .m-box--stretched-xl {
      padding: 6.6rem 1.8rem 6rem; } }

.m-box--with-footer {
  position: relative;
  padding-bottom: 5.6rem; }
  @media (min-width: 768px) {
    .m-box--with-footer {
      padding-bottom: 4.2rem; } }
  .m-box--with-footer footer {
    position: absolute;
    bottom: 1.2rem;
    left: 1.2rem;
    color: #9a9a9a;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left; }
    .m-box--with-footer footer > i {
      font-size: 2.4rem;
      vertical-align: middle; }

.m-box--medium {
  background: #eaeaea; }
  .m-box--medium .h--underlined:after {
    background: #d1d1d1; }

.m-box--dark {
  color: #fff;
  background: #646464; }

.m-box--black {
  color: #fff;
  background: #3a3a3a; }

.m-box--pri {
  color: #fff;
  background: #68c0ba; }

.m-box--ghost {
  border: 0.2rem solid #eaeaea;
  background: transparent; }

.m-box--dimmed {
  position: relative;
  min-height: 28rem;
  background-position: center;
  background-size: cover; }
  .m-box--dimmed:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(58, 58, 58, 0.7);
    content: ''; }
  .m-box--dimmed > div {
    position: relative;
    z-index: 1;
    color: #fff; }
    .m-box--dimmed > div .h--underlined:after {
      background: #fff; }

.m-box--full-width {
  width: 100%; }

.m-box--bubble-up {
  position: relative; }
  .m-box--bubble-up:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    content: '';
    transform: translateX(-50%);
    border: 1rem transparent solid;
    border-bottom-color: #fff; }
  .m-box--bubble-up.m-box--black:before {
    border: 1rem transparent solid;
    border-bottom-color: #3a3a3a; }

.m-boxed-icon-list {
  list-style-type: none; }

.m-boxed-icon-list__item {
  margin-bottom: 2.8rem; }
  @media (min-width: 768px) {
    .m-boxed-icon-list__item {
      display: table; } }
  .m-boxed-icon-list__item:last-child {
    margin-bottom: 0; }

.m-boxed-icon-list__icon-box {
  margin-bottom: 0.7rem; }
  @media (min-width: 768px) {
    .m-boxed-icon-list__icon-box {
      display: table-cell;
      margin-bottom: 0;
      padding-right: 2rem; } }
  .m-boxed-icon-list__icon-box > div {
    position: relative;
    min-height: 5.6rem;
    font-size: 0;
    line-height: 0; }
    @media (min-width: 768px) {
      .m-boxed-icon-list__icon-box > div {
        width: 23rem;
        height: 23rem;
        background: #fff;
        line-height: 26.5rem; } }
    .m-boxed-icon-list__icon-box > div > i {
      color: #9a9a9a;
      font-size: 4.6rem; }
      @media (min-width: 768px) {
        .m-boxed-icon-list__icon-box > div > i {
          font-size: 10rem; } }
    .m-boxed-icon-list__icon-box > div > img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 90%;
      max-height: 90%;
      transform: translateX(-50%) translateY(-50%); }

.m-boxed-icon-list__info {
  text-align: center; }
  @media (min-width: 768px) {
    .m-boxed-icon-list__info {
      display: table-cell;
      text-align: left;
      vertical-align: middle; } }
  .m-boxed-icon-list__info > strong.h3 {
    text-align: center; }
    @media (min-width: 768px) {
      .m-boxed-icon-list__info > strong.h3 {
        text-align: left !important; } }

.m-breadcrumbs:after {
  content: " ";
  display: block;
  clear: both; }

.m-breadcrumbs > li {
  position: relative;
  float: left;
  margin-right: 1.2rem;
  padding-right: 1.2rem;
  font-size: 1.3rem;
  line-height: 2.1rem; }
  .m-breadcrumbs > li:before {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -0.1rem;
    font-family: "strassacker-edition";
    font-size: 0.8rem;
    transform: translateX(50%) translateY(-50%); }
  .m-breadcrumbs > li:last-child:before {
    display: none; }
  .m-breadcrumbs > li > a {
    color: #646464; }
    .m-breadcrumbs > li > a:hover {
      color: #68c0ba; }

.ie9 .m-breadcrumbs:after {
  content: " ";
  display: block;
  clear: both; }

.m-button {
  overflow: hidden;
  min-width: 17.5rem;
  min-height: 4.7rem;
  padding: 1.2rem 1.8rem 1rem;
  border: 0.2rem solid #68c0ba;
  color: #68c0ba;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.1rem;
  text-overflow: ellipsis;
  transition: background 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s; }
  .m-button:hover {
    color: #fff;
    background: #68c0ba; }
  .m-button:disabled {
    border-color: #9a9a9a;
    color: #9a9a9a;
    cursor: default; }
    .m-button:disabled:hover {
      background: transparent; }
  .m-button > i.ico-play, .m-button > i.ico-pause {
    font-size: 2rem; }
  .m-button > i.ico-close {
    font-size: 1.4rem; }

.m-button--clunky {
  padding: 1.8rem 1.8rem 1.4rem; }

.m-button--round {
  width: 4.5rem;
  min-width: 0;
  height: 4.5rem;
  min-height: 0;
  padding: 0;
  border-radius: 50%;
  line-height: 4.5rem; }
  .m-button--round > i {
    position: relative;
    top: 0.3rem;
    font-size: 2rem; }

.m-button--light {
  border-color: #fff;
  color: #fff; }
  .m-button--light:hover {
    color: #68c0ba;
    background: #fff; }
  .m-button--light.m-button--filled {
    color: #68c0ba;
    background: #fff; }
    .m-button--light.m-button--filled:hover {
      color: #fff;
      background: transparent; }
  .m-button--light:disabled, .m-button--light.m-button--filled:disabled {
    border-color: #fff;
    color: #9a9a9a; }
    .m-button--light:disabled:hover, .m-button--light.m-button--filled:disabled:hover {
      border-color: #fff;
      color: #9a9a9a;
      background: #fff; }

.m-button--filled {
  color: #fff;
  background: #68c0ba; }
  .m-button--filled:hover {
    color: #68c0ba;
    background: transparent; }
  .m-button--filled:disabled {
    border-color: #68c0ba;
    color: #fff; }
    .m-button--filled:disabled:hover {
      background: #68c0ba; }

.m-button--link {
  min-width: 0;
  min-height: 0;
  padding: 0;
  border: none;
  background: transparent; }
  .m-button--link:hover {
    color: #48ada6;
    background: transparent; }

.m-button--full-width {
  width: 100%; }

.m-caption {
  display: block;
  margin-bottom: 2.8rem; }
  @media (min-width: 768px) {
    .m-caption {
      margin-bottom: 0; } }

.m-checkbox {
  display: none; }
  .m-checkbox:checked + label:before {
    font-family: "strassacker-edition"; }
  .m-checkbox:disabled {
    cursor: default; }
    .m-checkbox:disabled + label {
      cursor: default; }
      .m-checkbox:disabled + label:before {
        background: #eaeaea;
        opacity: 0.7; }
  .m-checkbox + label {
    position: relative;
    padding-left: 5.4rem;
    line-height: 4.2rem;
    cursor: pointer; }
    .m-checkbox + label:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 4.2rem;
      height: 4.2rem;
      color: #68c0ba;
      background: #eaeaea;
      font-size: 2.4rem;
      content: '';
      transform: translateY(-50%); }

.m-checkbox--small + label {
  padding-left: 3.3rem;
  font-size: 1.3rem;
  line-height: 2.1rem; }
  .m-checkbox--small + label:before {
    width: 2.1rem;
    height: 2.1rem;
    font-size: 1.6rem; }

.m-checkbox--light + label:before {
  background: #fff; }

.m-description-list dt,
.m-description-list dd {
  position: relative;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0.1rem solid #9a9a9a; }
  @media (min-width: 1024px) {
    .m-description-list dt,
    .m-description-list dd {
      float: left;
      width: 50%;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem; } }
  .m-description-list dt:first-of-type,
  .m-description-list dd:first-of-type {
    padding-top: 0; }
  .m-description-list dt:last-of-type:after,
  .m-description-list dd:last-of-type:after {
    position: absolute;
    bottom: -0.7rem;
    left: 0;
    display: none;
    width: 100%;
    border-bottom: 0.1rem solid #9a9a9a;
    content: ''; }
    @media (min-width: 1024px) {
      .m-description-list dt:last-of-type:after,
      .m-description-list dd:last-of-type:after {
        display: block; } }
  .m-description-list dt > strong, .m-description-list dt > span,
  .m-description-list dd > strong,
  .m-description-list dd > span {
    font-size: 2.4rem; }

.m-description-list dt {
  padding-top: 1.4rem;
  border-bottom: none;
  font-weight: 400; }
  @media (min-width: 1024px) {
    .m-description-list dt {
      padding-top: 1.4rem;
      border-bottom: 0.1rem solid #9a9a9a;
      font-weight: 300; } }
  .m-description-list dt:last-of-type {
    margin-bottom: 1.4rem; }
    @media (min-width: 1024px) {
      .m-description-list dt:last-of-type {
        margin-bottom: 0; } }

.m-description-list dd {
  padding-bottom: 1.4rem; }
  @media (min-width: 1024px) {
    .m-description-list dd {
      text-align: right; } }

.m-dot-indicator {
  display: block; }
  .m-dot-indicator:after {
    content: " ";
    display: block;
    clear: both; }

.m-dot-indicator__dot {
  float: left;
  width: 1rem;
  height: 1rem;
  margin-right: 0.4rem;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  transition: background 0.3s; }
  .m-dot-indicator__dot:last-child {
    margin-right: 0; }
  .m-dot-indicator__dot.active {
    background: #db701d; }

.m-drop-down {
  position: relative;
  z-index: 9;
  width: 100%;
  min-width: 6.5rem;
  height: 4.2rem;
  color: #68c0ba;
  background: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: left; }
  .m-drop-down.open {
    z-index: 10; }
    .m-drop-down.open .m-drop-down__selected-option {
      box-shadow: 0 0 0.4rem rgba(58, 58, 58, 0.2); }
    .m-drop-down.open .m-drop-down__options {
      position: relative;
      max-height: 19.6rem;
      box-shadow: 0 0.2rem 0.4rem rgba(58, 58, 58, 0.2); }
  .m-drop-down.disabled .m-drop-down__selected-option {
    cursor: default; }

.m-drop-down--medium {
  background: #eaeaea; }
  .m-drop-down--medium .m-drop-down__options {
    background: #eaeaea; }
    .m-drop-down--medium .m-drop-down__options > li {
      border-top-color: #fafafa; }
  .m-drop-down--medium .m-drop-down__search input {
    background: #eaeaea; }

.m-drop-down--unselectable .m-drop-down__selected-option {
  color: #fff;
  background: #9a9a9a; }
  .m-drop-down--unselectable .m-drop-down__selected-option > .ico-close {
    font-size: 1.3rem; }

.m-drop-down__selected-option {
  position: relative;
  overflow: hidden;
  height: 4.2rem;
  padding: 0 3.2rem 0 1.2rem;
  line-height: 4.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: box-shadow 0.3s; }
  .m-drop-down__selected-option > i {
    position: absolute;
    top: 0.3rem;
    right: 1.2rem; }

.m-drop-down__options {
  z-index: 1;
  overflow: auto;
  max-height: 0;
  background: #fff;
  font-size: 1.5rem;
  transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1), box-shadow 0.3s; }
  .m-drop-down__options > li {
    overflow: hidden;
    height: 4.2rem;
    padding: 0.1rem 1.2rem 0;
    border-top: 0.1rem solid #f2f2f2;
    line-height: 4.48rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background 0.3s; }
    .m-drop-down__options > li:hover, .m-drop-down__options > li:focus {
      background: #f2f2f2; }
    .m-drop-down__options > li.disabled {
      display: none; }
    .m-drop-down__options > li strong {
      font-weight: 600; }

.m-drop-down__search {
  overflow: hidden;
  max-height: 0;
  border-bottom: 0.1rem solid #eaeaea;
  transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-drop-down__search.active {
    max-height: 4.2rem;
    box-shadow: 0 0 0.4rem rgba(58, 58, 58, 0.2); }
  .m-drop-down__search input {
    border: none; }

.ng-touched.ng-invalid > .m-drop-down:not(.m-drop-down--novalidate) {
  border-color: rgba(229, 53, 75, 0.75);
  background: white; }
  .ng-touched.ng-invalid > .m-drop-down:not(.m-drop-down--novalidate) .m-drop-down__selected-option {
    border: 0.2rem solid #e5354b; }

.ng-touched.ng-valid > .m-drop-down:not(.m-drop-down--novalidate) {
  border-color: rgba(104, 192, 186, 0.75);
  background: #e6f5f4; }
  .ng-touched.ng-valid > .m-drop-down:not(.m-drop-down--novalidate) .m-drop-down__selected-option {
    border: 0.1rem solid #68c0ba; }

.m-feature-icon {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem; }
  .m-feature-icon a {
    color: #fff; }
    .m-feature-icon a:hover {
      color: #68c0ba; }
      .m-feature-icon a:hover > i {
        color: #68c0ba;
        transition: color 0.3s; }
  .m-feature-icon i {
    display: block;
    font-size: 4.6rem;
    line-height: 5.6rem;
    transition: none; }

.m-filter-flyout-button {
  position: fixed;
  z-index: 4999;
  right: 2rem;
  bottom: 2rem;
  transition: opacity 0.3s; }
  @media (min-width: 768px) {
    .m-filter-flyout-button {
      display: none; } }
  .m-filter-flyout-button.hidden {
    pointer-events: none;
    opacity: 0; }

.m-flyout {
  position: absolute;
  display: none;
  width: auto;
  min-width: 28rem;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
  z-index: 4999; }
  .m-flyout div {
    position: relative;
    padding: 1.8rem;
    border-radius: 0.3rem;
    color: #fff;
    background: #646464;
    line-height: 2.1rem; }
    .m-flyout div:before {
      position: absolute;
      bottom: 100%;
      left: 50%;
      content: '';
      transform: translateX(-50%);
      border: 0.8rem transparent solid;
      border-bottom-color: #646464; }
  .m-flyout .m-social-button {
    margin-right: 0.4rem;
    margin-left: 0.4rem;
    border-color: #fff;
    color: #fff; }
    .m-flyout .m-social-button:hover {
      background: #fff; }
      .m-flyout .m-social-button:hover > i {
        color: #646464; }

.m-flyout--offset-left div:before {
  left: 66%; }

.m-form fieldset {
  margin-bottom: 1.4rem;
  padding: 1.8rem;
  background: #eaeaea; }
  .m-form fieldset:after {
    content: " ";
    display: block;
    clear: both; }
  .m-form fieldset:last-child {
    margin-bottom: 0; }
  .m-form fieldset legend {
    float: left;
    width: 100%;
    margin-bottom: 1.4rem;
    font-weight: 300;
    text-align: left;
    letter-spacing: 0.1rem; }
    @media (min-width: 1024px) {
      .m-form fieldset legend {
        margin-bottom: 2.8rem; } }
  .m-form fieldset .m-checkbox + label:before {
    background: #fff;
    text-align: center; }

.m-form--with-sidebar fieldset {
  width: 100%; }
  @media (min-width: 1024px) {
    .m-form--with-sidebar fieldset {
      width: 66.6666666667%; } }

.m-form__group {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0.7rem;
  font-size: 1.6rem; }
  .m-form__group:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 1024px) {
    .m-form__group {
      margin-bottom: 1.4rem; } }
  .m-form__group:last-child {
    margin-bottom: 0; }
  .m-form__group > label {
    float: left;
    width: 100%;
    padding-right: 2rem;
    text-align: left; }
    @media (min-width: 1024px) {
      .m-form__group > label {
        position: relative;
        top: 2.1rem;
        width: 33.3333333333%;
        line-height: 2.1rem;
        text-align: right;
        transform: translateY(-50%); } }
  .m-form__group > div {
    float: left;
    width: 100%; }
    @media (min-width: 1024px) {
      .m-form__group > div {
        width: 66.6666666667%; } }
  .m-form__group > aside {
    position: absolute;
    top: 0.7rem;
    left: 100%;
    display: none;
    width: 50%;
    margin-left: 3.8rem;
    padding-left: 4.9rem;
    font-size: 1.5rem;
    line-height: 2.1rem; }
    @media (min-width: 1024px) {
      .m-form__group > aside {
        display: block; } }
    .m-form__group > aside:before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 3.5rem;
      height: 3.5rem;
      padding-top: 1.6rem;
      border: 0.2rem solid #9a9a9a;
      border-radius: 50%;
      color: #9a9a9a;
      font-family: "strassacker-edition";
      font-size: 1.6rem;
      line-height: 0;
      text-align: center;
      vertical-align: middle; }
  .m-form__group > .m-button {
    width: 100%;
    margin-top: 1.4rem; }
    @media (min-width: 768px) {
      .m-form__group > .m-button {
        width: auto;
        margin-top: 0.7rem; } }

.m-form__message {
  position: relative;
  margin-top: 1.4rem;
  padding: 1.2rem;
  color: #fff;
  background: #e5354b; }
  .m-form__message:before {
    position: absolute;
    bottom: 100%;
    left: 5%;
    content: '';
    border: 0.7rem transparent solid;
    border-bottom-color: #e5354b; }
  .m-form__message > i {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    margin-top: 0.2rem;
    font-size: 1.8rem;
    transform: translateY(-50%); }
  .m-form__message > p {
    padding-left: 3rem;
    line-height: 2.1rem;
    text-align: left; }

.m_form__password-toggle {
  position: relative; }
  .m_form__password-toggle.active > button {
    color: #9a9a9a; }
  .m_form__password-toggle > button {
    position: absolute;
    top: 50%;
    right: 1.2rem;
    color: #68c0ba;
    transition: color 0.3s;
    transform: translateY(-50%); }
    .m_form__password-toggle > button > i {
      position: relative;
      top: 0.3rem;
      font-size: 1.8rem; }

.ie9 .m-form__group > aside:before {
  line-height: 2; }

.m-guarantee-teaser {
  display: table;
  width: 100%;
  margin-bottom: 1.4rem;
  padding-top: 2.1rem;
  padding-bottom: 2.1rem;
  border-top: 0.3rem solid #eaeaea;
  border-bottom: 0.3rem solid #eaeaea;
  table-layout: fixed; }
  @media (min-width: 1024px) {
    .m-guarantee-teaser {
      margin-bottom: 0; } }

.m-guarantee-teaser__icon {
  display: table-cell;
  width: 90px;
  padding-right: 2rem;
  vertical-align: middle; }
  .m-guarantee-teaser__icon > img {
    width: 100%; }

.m-guarantee-teaser__info {
  display: table-cell;
  vertical-align: middle; }
  .m-guarantee-teaser__info > h4 {
    margin-bottom: 0.35rem;
    text-align: left; }
  .m-guarantee-teaser__info > div {
    font-size: 1.6rem; }

.m-hero-icon {
  display: inline-block;
  width: 5.6rem;
  height: 5.6rem;
  border: 0.2rem solid #68c0ba;
  border-radius: 50%;
  color: #68c0ba;
  font-size: 3.2rem;
  line-height: 6.3rem;
  text-align: center; }

.m-hero-icon--error {
  border-color: #e5354b;
  color: #e5354b; }

.m-hero-icon--simple {
  border-width: 0; }

.m-icon-bar {
  display: flex;
  margin-bottom: -1.2rem;
  flex-wrap: wrap;
  list-style: none; }
  .m-icon-bar > li {
    margin-right: 1.2rem;
    margin-bottom: 1.2rem;
    font-size: 0;
    line-height: 0; }
    .m-icon-bar > li :last-child {
      margin-right: 0; }

.m-icon-list {
  width: 100%;
  text-align: center;
  list-style: none; }
  .m-icon-list:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .m-icon-list {
      display: table;
      table-layout: fixed; } }
  .m-icon-list > li {
    float: left;
    overflow: hidden;
    width: 50%;
    margin-bottom: 2.8rem;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }
    @media (min-width: 768px) {
      .m-icon-list > li {
        float: none;
        display: table-cell;
        margin-bottom: 0; } }
    .m-icon-list > li > i {
      display: block;
      margin-bottom: 1.4rem;
      color: #9a9a9a;
      font-size: 4.6rem; }

.m-isotope {
  display: block;
  width: 100%;
  margin-right: -1rem;
  margin-bottom: -2rem;
  margin-left: -1rem; }

.m-isotope__brick {
  display: block;
  width: 50%;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  font-size: 0;
  line-height: 0;
  cursor: pointer; }
  @media (min-width: 768px) {
    .m-isotope__brick {
      width: 33.3333333333%; } }
  .m-isotope__brick img {
    width: 100%; }

.m-isotope__brick--large {
  width: 100%; }
  @media (min-width: 768px) {
    .m-isotope__brick--large {
      width: 66.6666666667%; } }

.m-landing-header {
  position: relative;
  min-height: 43.4rem;
  color: #fff;
  background-size: cover;
  margin-top: 4.2rem; }
  @media (min-width: 768px) {
    .m-landing-header {
      margin-top: 5.6rem; } }
  .m-landing-header:before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(58, 58, 58, 0.7);
    content: ''; }
  .m-landing-header > div {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 64rem;
    padding: 1.2rem;
    text-align: center;
    transform: translateX(-50%) translateY(-50%); }
    .m-landing-header > div h1 {
      font-size: 2.4rem;
      line-height: 3.5rem; }
      @media (min-width: 768px) {
        .m-landing-header > div h1 {
          font-size: 3.2rem;
          line-height: 4.2rem; } }

.m-landing-header--with-tools {
  margin-bottom: 12.6rem; }
  @media (min-width: 768px) {
    .m-landing-header--with-tools {
      margin-bottom: 5.6rem; } }

.m-landing-header__tools {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  padding-top: 2.1rem;
  padding-right: 2rem;
  padding-left: 2rem;
  background: #68c0ba; }
  @media (min-width: 768px) {
    .m-landing-header__tools {
      height: 8.4rem; } }
  .m-landing-header__tools > img {
    position: absolute;
    bottom: 1.4rem;
    left: 2rem;
    display: none; }
    @media (min-width: 768px) {
      .m-landing-header__tools > img {
        display: inline;
        max-width: 13rem; } }
    @media (min-width: 1024px) {
      .m-landing-header__tools > img {
        max-width: 16rem; } }
  .m-landing-header__tools .m-button {
    display: block;
    width: 100%;
    margin-bottom: 1.4rem; }
    @media (min-width: 768px) {
      .m-landing-header__tools .m-button {
        float: left;
        width: 34%;
        margin-bottom: 0;
        margin-left: 1%; } }
    @media (min-width: 1024px) {
      .m-landing-header__tools .m-button {
        width: 22%; } }
  .m-landing-header__tools > p {
    float: left;
    display: none;
    width: 30%;
    padding-left: 16rem;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: right;
    opacity: 0; }
    @media (min-width: 768px) {
      .m-landing-header__tools > p {
        display: block; } }
    @media (min-width: 1024px) {
      .m-landing-header__tools > p {
        width: 54%;
        opacity: 1; } }

.m-link {
  display: inline-block;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  color: #646464;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-rendering: optimizeLegibility; }
  @media (min-width: 1024px) {
    .m-link {
      padding-right: 0;
      padding-left: 0;
      text-align: left; } }
  .m-link:hover {
    color: #68c0ba; }
    .m-link:hover > i {
      color: #68c0ba; }
  .m-link > i {
    display: block;
    margin-right: 0.4rem;
    color: #9a9a9a;
    font-size: 2.4rem;
    vertical-align: middle;
    transition: color 0.3s; }
    @media (min-width: 1024px) {
      .m-link > i {
        display: inline-block; } }

.m-link--sec {
  color: #68c0ba; }
  .m-link--sec:hover {
    color: #48ada6; }
  .m-link--sec > i {
    color: #68c0ba; }
    .m-link--sec > i:hover {
      color: #48ada6; }

.m-link--light {
  color: #fff; }
  .m-link--light:hover {
    color: #68c0ba; }
    .m-link--light:hover > i {
      color: #68c0ba; }
  .m-link--light > i {
    color: #fff; }

.m-link--small {
  font-size: 1.2rem; }
  .m-link--small > i {
    margin-right: 0;
    font-size: 1.3rem; }

.m-link-list__headline {
  display: block;
  margin-bottom: 1.4rem;
  padding-bottom: 0.7rem;
  border-bottom: 0.2rem solid rgba(154, 154, 154, 0.25);
  text-transform: uppercase;
  letter-spacing: 0.1rem; }
  .m-link-list__headline > i {
    position: relative;
    top: 0.1rem;
    margin-right: 0.4rem; }

.m-link-list__list {
  list-style: none;
  transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-link-list__list > li {
    margin-bottom: 0.7rem; }
    .m-link-list__list > li > a {
      color: #646464; }
      .m-link-list__list > li > a:hover {
        color: #68c0ba; }

.m-list, .l-standard-content__richtext ul {
  list-style: none;
  list-style-position: inside; }
  .m-list li, .l-standard-content__richtext ul li {
    position: relative;
    margin-bottom: 0.7rem;
    padding-left: 1.8rem; }
    .m-list li:before, .l-standard-content__richtext ul li:before {
      position: absolute;
      top: 1.4rem;
      left: 0;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: #d1d1d1;
      content: '';
      transform: translateY(-50%); }

.m-loading-spinner:before,
.m-loading-spinner:after,
.m-loading-spinner {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  animation: loading-spinner 1s infinite ease-in-out;
  animation-fill-mode: both; }

.m-loading-spinner {
  position: relative;
  margin: 0 auto;
  text-indent: -9999rem;
  transform: translateZ(0);
  animation-delay: -0.16s; }

.m-loading-spinner:before {
  left: -2.5rem;
  animation-delay: -0.32s; }

.m-loading-spinner:after {
  left: 2.5rem; }

.m-loading-spinner:before,
.m-loading-spinner:after {
  position: absolute;
  top: 0;
  content: ''; }

@keyframes loading-spinner {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5rem 0 -0.8rem #eaeaea; }
  40% {
    box-shadow: 0 1.5rem 0 0 #eaeaea; } }

.m-loading-spinner-2 {
  margin: 0.3rem auto 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: relative;
  text-indent: -9999rem;
  animation: loading-spinner-2 1.3s infinite linear;
  transform: translateZ(0) scale(0.45); }

@keyframes loading-spinner-2 {
  0%,
  100% {
    box-shadow: 0 -3rem 0 0.2rem #fff, 2rem -2rem 0 0 #fff, 3rem 0 0 -1rem #fff, 2rem 2rem 0 -1rem #fff, 0 3rem 0 -1rem #fff, -2rem 2rem 0 -1rem #fff, -3rem 0 0 -1rem #fff, -2rem -2rem 0 0 #fff; }
  12.5% {
    box-shadow: 0 -3rem 0 0 #fff, 2rem -2rem 0 0.2rem #fff, 3rem 0 0 0 #fff, 2rem 2rem 0 -1rem #fff, 0 3rem 0 -1rem #fff, -2rem 2rem 0 -1rem #fff, -3rem 0 0 -1rem #fff, -2rem -2rem 0 -1rem #fff; }
  25% {
    box-shadow: 0 -3rem 0 -0.5rem #fff, 2rem -2rem 0 0 #fff, 3rem 0 0 0.2rem #fff, 2rem 2rem 0 0 #fff, 0 3rem 0 -1rem #fff, -2rem 2rem 0 -1rem #fff, -3rem 0 0 -1rem #fff, -2rem -2rem 0 -1rem #fff; }
  37.5% {
    box-shadow: 0 -3rem 0 -1rem #fff, 2rem -2rem 0 -1rem #fff, 3rem 0 0 0 #fff, 2rem 2rem 0 0.2rem #fff, 0 3rem 0 0 #fff, -2rem 2rem 0 -1rem #fff, -3rem 0 0 -1rem #fff, -2rem -2rem 0 -1rem #fff; }
  50% {
    box-shadow: 0 -3rem 0 -1rem #fff, 2rem -2rem 0 -1rem #fff, 3rem 0 0 -1rem #fff, 2rem 2rem 0 0 #fff, 0 3rem 0 0.2rem #fff, -2rem 2rem 0 0 #fff, -3rem 0 0 -1rem #fff, -2rem -2rem 0 -1rem #fff; }
  62.5% {
    box-shadow: 0 -3rem 0 -1rem #fff, 2rem -2rem 0 -1rem #fff, 3rem 0 0 -1rem #fff, 2rem 2rem 0 -1rem #fff, 0 3rem 0 0 #fff, -2rem 2rem 0 0.2rem #fff, -3rem 0 0 0 #fff, -2rem -2rem 0 -1rem #fff; }
  75% {
    box-shadow: 0 -3rem 0 -1rem #fff, 2rem -2rem 0 -1rem #fff, 3rem 0 0 -1rem #fff, 2rem 2rem 0 -1rem #fff, 0 3rem 0 -1rem #fff, -2rem 2rem 0 0 #fff, -3rem 0 0 0.2rem #fff, -2rem -2rem 0 0 #fff; }
  87.5% {
    box-shadow: 0 -3rem 0 0 #fff, 2rem -2rem 0 -1rem #fff, 3rem 0 0 -1rem #fff, 2rem 2rem 0 -1rem #fff, 0 3rem 0 -1rem #fff, -2rem 2rem 0 0 #fff, -3rem 0 0 0 #fff, -2rem -2rem 0 0.2rem #fff; } }

.m-mega-menu {
  width: 100%; }
  .m-mega-menu:after {
    content: " ";
    display: block;
    clear: both; }

.m-mega-menu--active-search .m-mega-menu__item {
  transform: scaleX(0);
  transform-origin: left center;
  opacity: 0; }

.m-mega-menu--active-search .m-mega-menu__search .m-search-input {
  transform: translateX(-50%) translateY(-50%) scaleX(1);
  opacity: 1; }

.m-mega-menu__item {
  float: left;
  height: 7rem;
  margin-right: 3.2rem;
  transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1), opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: 0.1s; }
  .m-mega-menu__item:hover > .m-mega-menu__sub-menu, .m-mega-menu__item:focus > .m-mega-menu__sub-menu {
    max-height: 47.6rem;
    transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transition-delay: 0.1s; }
    .m-mega-menu__item:hover > .m-mega-menu__sub-menu > div, .m-mega-menu__item:focus > .m-mega-menu__sub-menu > div {
      opacity: 1; }
  .m-mega-menu__item.m-mega-menu__item--expanding:hover > a {
    color: #646464; }
    .m-mega-menu__item.m-mega-menu__item--expanding:hover > a:after {
      opacity: 1; }
  .m-mega-menu__item.m-mega-menu__item--expanding > a:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    transition: opacity 0.3s;
    transition-delay: 0.1s;
    transform: translateX(-50%);
    opacity: 0;
    border: 1.2rem transparent solid;
    border-bottom-color: #eaeaea; }
  .m-mega-menu__item > a {
    position: relative;
    display: inline-block;
    height: 100%;
    padding-top: 2.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }
    .m-mega-menu__item > a:hover {
      color: #646464; }
    .m-mega-menu__item > a.active {
      color: #646464; }

.m-mega-menu__search {
  float: right;
  padding-top: 2.6rem;
  color: #68c0ba;
  cursor: pointer;
  transition: color 0.3s; }
  .m-mega-menu__search:hover {
    color: #646464; }
  .m-mega-menu__search .m-search-input {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    max-width: 118rem;
    transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1), opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transform: translateX(-50%) translateY(-50%) scaleX(0);
    transform-origin: right center;
    opacity: 0; }
    .m-mega-menu__search .m-search-input:before {
      left: 2.4rem; }
      @media (min-width: 1200px) {
        .m-mega-menu__search .m-search-input:before {
          left: 1.2rem; } }
    .m-mega-menu__search .m-search-input input[type=text] {
      border: none;
      background: #eaeaea; }
      .m-mega-menu__search .m-search-input input[type=text] + .m-search-input__close-button {
        right: 2.4rem; }
        @media (min-width: 1200px) {
          .m-mega-menu__search .m-search-input input[type=text] + .m-search-input__close-button {
            right: 1.2rem; } }
    .m-mega-menu__search .m-search-input .ico-spinner {
      left: 1.8rem; }
      @media (min-width: 1200px) {
        .m-mega-menu__search .m-search-input .ico-spinner {
          left: 0.6rem; } }
    .m-mega-menu__search .m-search-input > div {
      padding-right: 1rem;
      padding-left: 1rem; }
      @media (min-width: 1200px) {
        .m-mega-menu__search .m-search-input > div {
          padding-right: 0;
          padding-left: 0; } }

.m-mega-menu__sub-menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  overflow: hidden;
  max-height: 0;
  background: #eaeaea;
  font-size: 1.6rem;
  transition: max-height 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-mega-menu__sub-menu:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0.8rem;
    background: #db701d;
    content: ''; }
  .m-mega-menu__sub-menu > div {
    padding: 2.8rem 0;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    opacity: 0;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto; }
    .m-mega-menu__sub-menu > div:after {
      content: " ";
      display: block;
      clear: both; }
    .m-mega-menu__sub-menu > div a {
      white-space: normal; }

.m-mega-menu__column {
  width: 25%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  .m-mega-menu__column > .m-box {
    margin-bottom: 1.4rem; }
    .m-mega-menu__column > .m-box.m-box--stretched {
      padding-top: 2.24rem;
      padding-bottom: 1.4rem; }
      @media (min-width: 1200px) {
        .m-mega-menu__column > .m-box.m-box--stretched {
          padding-top: 2.8rem;
          padding-bottom: 2.296rem; } }

.m-mega-menu__column--wide {
  width: 33.3333333333%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  .m-mega-menu__column--wide .m-box {
    position: relative;
    height: 29.6rem; }
    .m-mega-menu__column--wide .m-box > div {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%); }

.m-mega-menu__column--wider {
  width: 50%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  .m-mega-menu__column--wider .m-link-list__list {
    float: left;
    width: 50%; }

.m-mega-menu__column--even-wider {
  width: 75%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  .m-mega-menu__column--even-wider > div {
    position: relative;
    top: 0.1rem;
    width: 100%; }
    .m-mega-menu__column--even-wider > div > .m-button {
      float: left;
      display: block;
      width: 9.1111111111%;
      min-width: 0;
      height: 7rem;
      margin-right: 2%;
      margin-bottom: 2%;
      font-size: 2.4rem;
      line-height: 4.2rem; }
      @media (min-width: 1200px) {
        .m-mega-menu__column--even-wider > div > .m-button {
          height: 7.8rem;
          line-height: 4.9rem; } }
      .m-mega-menu__column--even-wider > div > .m-button:nth-child(9n) {
        margin-right: 0; }
      .m-mega-menu__column--even-wider > div > .m-button:last-child {
        padding: 0;
        padding-top: 2.1rem;
        font-size: 1.2rem;
        line-height: 1.2rem;
        text-align: center;
        letter-spacing: 0; }
        @media (min-width: 1200px) {
          .m-mega-menu__column--even-wider > div > .m-button:last-child {
            line-height: 1.5rem;
            letter-spacing: 0.1rem; } }

.m-mega-menu__footer {
  float: left;
  width: 100%;
  margin-top: 1.4rem; }
  .m-mega-menu__footer > .m-box {
    text-align: left; }
    .m-mega-menu__footer > .m-box:after {
      content: " ";
      display: block;
      clear: both; }
    .m-mega-menu__footer > .m-box > div:nth-child(1) {
      padding-top: 0.4rem;
      width: 25%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; }
      .m-mega-menu__footer > .m-box > div:nth-child(1) > .h5 {
        margin-bottom: 0;
        font-size: 1.5rem;
        text-align: left; }
    .m-mega-menu__footer > .m-box > div:nth-child(2) {
      width: 25%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; }
      .m-mega-menu__footer > .m-box > div:nth-child(2) > i {
        position: relative;
        top: 0.4rem;
        float: left;
        display: block;
        width: 20%;
        font-size: 4.6rem; }
      .m-mega-menu__footer > .m-box > div:nth-child(2) > div {
        float: left;
        width: 80%; }
        .m-mega-menu__footer > .m-box > div:nth-child(2) > div > strong {
          display: block; }
    .m-mega-menu__footer > .m-box > div:nth-child(3) {
      padding-top: 1.4rem;
      text-align: right;
      width: 50%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; }
      .m-mega-menu__footer > .m-box > div:nth-child(3) > .m-button {
        margin-left: 2rem; }

.ie9 .m-mega-menu__search .m-search-input {
  top: 0;
  left: 0;
  margin: 0 auto; }

.m-nav-bar {
  list-style: none; }
  .m-nav-bar:after {
    content: " ";
    display: block;
    clear: both; }
  .m-nav-bar > li {
    float: left;
    margin-right: 4rem; }
    .m-nav-bar > li:last-child {
      margin-right: 0; }
    .m-nav-bar > li .m-drop-down {
      margin-top: -0.6rem; }
    .m-nav-bar > li .m-link {
      line-height: 2.24rem; }
      @media (min-width: 1024px) {
        .m-nav-bar > li .m-link {
          line-height: 2.8rem; } }

.m-newsletter-form .m-form__group {
  float: none; }
  .m-newsletter-form .m-form__group .m-form__message {
    width: 100%; }
  .m-newsletter-form .m-form__group > div.table {
    width: 100%; }
    @media (min-width: 768px) {
      .m-newsletter-form .m-form__group > div.table {
        display: table;
        table-layout: fixed; } }
    .m-newsletter-form .m-form__group > div.table > div {
      width: 100%; }
      @media (min-width: 768px) {
        .m-newsletter-form .m-form__group > div.table > div {
          display: table-cell;
          width: 75%;
          vertical-align: top; } }
      @media (min-width: 768px) {
        .m-newsletter-form .m-form__group > div.table > div:nth-child(2) {
          width: 25%; } }
      .m-newsletter-form .m-form__group > div.table > div input {
        width: 100%;
        height: 4.7rem;
        border: 0.2rem solid #fff;
        border-radius: 0;
        color: #fff;
        background: transparent;
        text-align: center; }
        @media (min-width: 768px) {
          .m-newsletter-form .m-form__group > div.table > div input {
            text-align: left; } }
      .m-newsletter-form .m-form__group > div.table > div .m-button {
        width: 100%;
        min-width: 0;
        height: 4.2rem;
        border-width: 0;
        border-bottom: 0;
        border-radius: 0;
        font-size: 1.3rem; }
        .m-newsletter-form .m-form__group > div.table > div .m-button:hover {
          border-color: #68c0ba;
          color: #fff;
          background: #68c0ba; }
        .m-newsletter-form .m-form__group > div.table > div .m-button:disabled {
          border-color: #68c0ba;
          color: #fff;
          background: #68c0ba; }
          .m-newsletter-form .m-form__group > div.table > div .m-button:disabled:hover {
            border-color: #68c0ba;
            color: #fff;
            background: #68c0ba; }

.m-notification {
  position: fixed;
  z-index: 8000;
  right: 0;
  bottom: -8.4rem;
  left: -29rem;
  display: table;
  width: 100%;
  height: 8.4rem;
  color: #fff;
  background: rgba(34, 34, 34, 0.8);
  transition: bottom 0.6s cubic-bezier(0.55, 0, 0.1, 1), left 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-notification:not(.m-notification--left) {
    left: 0; }
  .m-notification.active {
    bottom: 0; }
    .m-notification.active .m-notification__icon > i {
      transform: scale(1);
      opacity: 1; }

.m-notification--error .m-notification__icon {
  background: #e5354b; }

.m-notification--left {
  position: fixed;
  top: 50%;
  left: -29rem;
  width: 29rem;
  font-size: 1.6rem;
  line-height: 2.1rem;
  transform: translateY(-50%); }
  .m-notification--left.active {
    left: 1.2rem; }
  .m-notification--left .m-notification__icon {
    width: 6rem; }

.m-notification__icon {
  display: table-cell;
  width: 8.4rem;
  background: #68c0ba;
  text-align: center;
  vertical-align: middle; }
  .m-notification__icon > i {
    position: relative;
    top: 0.2rem;
    display: block;
    font-size: 3.2rem;
    transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1) 0.3s, opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1) 0.3s;
    transform: scale(0);
    opacity: 0; }

.m-notification__message {
  display: table-cell;
  padding-right: 1rem;
  padding-left: 2rem;
  vertical-align: middle; }

.m-pagination {
  display: table;
  width: 100%; }

.m-pagination__prev,
.m-pagination__next {
  display: table-cell;
  width: 4.2rem; }
  .m-pagination__prev > .m-button,
  .m-pagination__next > .m-button {
    width: 100%;
    min-width: 0;
    height: 4.2rem;
    min-height: 0;
    padding-right: 0;
    padding-left: 0; }
    .m-pagination__prev > .m-button:hover,
    .m-pagination__next > .m-button:hover {
      border-color: #68c0ba;
      color: #fff;
      background: #68c0ba; }

.m-pagination__center {
  display: table-cell;
  color: #68c0ba;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer; }
  .m-pagination__center input {
    display: inline-block;
    width: 2.6rem;
    height: 2.8rem;
    padding: 0.4rem 0.2rem;
    font-size: 1.3rem;
    text-align: center;
    -moz-appearance: textfield; }
    .m-pagination__center input::-webkit-outer-spin-button, .m-pagination__center input::-webkit-inner-spin-button {
      visibility: hidden;
      margin: 0;
      -webkit-appearance: none; }

.m-phone-number {
  font-size: 1.6rem; }
  .m-phone-number > strong {
    display: block;
    font-size: 2rem;
    font-weight: 300;
    line-height: 3.5rem; }
    @media (min-width: 1024px) {
      .m-phone-number > strong {
        font-size: 2.4rem; } }

.m-price-tag {
  font-size: 3.2rem;
  font-weight: 400; }
  .m-price-tag > small {
    display: block; }

.m-product-table {
  width: 100%;
  font-size: 1.6rem;
  text-align: left; }
  .m-product-table thead {
    display: none;
    border-bottom: 0.2rem solid #eaeaea; }
    @media (min-width: 1024px) {
      .m-product-table thead {
        display: table-header-group; } }
    .m-product-table thead th {
      padding-bottom: 1.4rem;
      font-weight: 400;
      text-align: left; }
      .m-product-table thead th:last-child {
        text-align: right; }
  .m-product-table tbody tr {
    border-bottom: 0.2rem solid #eaeaea; }
    .m-product-table tbody tr:last-child {
      border-bottom: none; }
  .m-product-table tbody td {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem; }
  .m-product-table td {
    display: none;
    vertical-align: top; }
    @media (min-width: 1024px) {
      .m-product-table td {
        display: table-cell; } }
    .m-product-table td:first-child {
      display: table-cell;
      width: 50%; }
      .m-product-table td:first-child:after {
        content: " ";
        display: block;
        clear: both; }
    .m-product-table td:last-child {
      text-align: right; }
  .m-product-table .m-drop-down {
    width: 100%; }
    @media (min-width: 1024px) {
      .m-product-table .m-drop-down {
        width: 6.5rem; } }
  .m-product-table .ng-valid > .m-drop-down,
  .m-product-table .ng-invalid > .m-drop-down {
    border-color: #eaeaea;
    background: #eaeaea; }
  .m-product-table .ng-valid .m-drop-down__selected-option,
  .m-product-table .ng-invalid .m-drop-down__selected-option {
    border: 0.1rem solid #eaeaea; }

.m-product-table__thumbnail {
  float: right;
  width: 33.3333333333%;
  text-align: center; }
  @media (min-width: 1024px) {
    .m-product-table__thumbnail {
      float: left;
      display: table; } }
  .m-product-table__thumbnail > div {
    position: relative;
    height: auto;
    margin-bottom: 0.7rem;
    padding: 1.2rem;
    background: #eaeaea;
    vertical-align: middle; }
    @media (min-width: 768px) {
      .m-product-table__thumbnail > div {
        height: 22.4rem; } }
    @media (min-width: 1024px) {
      .m-product-table__thumbnail > div {
        display: table-cell;
        width: 100%;
        height: 16.8rem;
        margin-bottom: 0; } }
    @media (min-width: 1200px) {
      .m-product-table__thumbnail > div {
        height: 19.6rem; } }
    .m-product-table__thumbnail > div:before {
      display: block;
      padding-top: 100%;
      content: ''; }
    .m-product-table__thumbnail > div img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      max-width: 90%;
      max-height: 90%;
      transform: translateX(-50%) translateY(-50%); }
  @media (min-width: 1024px) {
    .m-product-table__thumbnail .m-drop-down {
      display: none; } }

.m-product-table__info {
  float: left;
  width: 66.6666666667%;
  padding-right: 1.2rem; }
  @media (min-width: 1024px) {
    .m-product-table__info {
      padding-right: 2rem;
      padding-left: 2rem; } }
  .m-product-table__info > span {
    color: #9a9a9a;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }
  .m-product-table__info > strong, .m-product-table__info > a > strong {
    display: block;
    margin-bottom: 2.8rem;
    line-height: 2.1rem; }
  .m-product-table__info > p.meta {
    margin-bottom: 1.4rem; }
    @media (min-width: 1024px) {
      .m-product-table__info > p.meta {
        margin-bottom: 0; } }
    .m-product-table__info > p.meta > span {
      display: none; }
      @media (min-width: 1024px) {
        .m-product-table__info > p.meta > span {
          display: block; } }
      .m-product-table__info > p.meta > span.mobile {
        display: block; }
        @media (min-width: 1024px) {
          .m-product-table__info > p.meta > span.mobile {
            display: none; } }
  .m-product-table__info .m-link {
    margin-right: 1.2rem; }
    .m-product-table__info .m-link > i {
      display: inline-block; }
  .m-product-table__info .wish-list-button {
    display: none; }
    @media (min-width: 1024px) {
      .m-product-table__info .wish-list-button {
        display: inline-block; } }

.m-product-tile {
  position: relative;
  display: block;
  color: #646464;
  text-align: center; }
  @media (min-width: 768px) {
    .m-product-tile:hover {
      color: #646464; }
      .m-product-tile:hover .m-product-tile__image:before {
        opacity: 1; }
      .m-product-tile:hover .m-product-tile__image:after {
        opacity: 1; }
      .m-product-tile:hover .m-product-tile__image > i {
        pointer-events: auto;
        opacity: 1; }
      .m-product-tile:hover .m-product-tile__title {
        color: #fff; }
        .m-product-tile:hover .m-product-tile__title.h--underlined:after {
          width: 40%;
          background: #fff; }
      .m-product-tile:hover .m-product-tile__price,
      .m-product-tile:hover .m-product-tile__artist {
        color: #fff; } }

.m-product-tile__image {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 1.4rem;
  padding: 1.8rem;
  background: #eaeaea; }
  .m-product-tile__image:before {
    position: relative;
    z-index: 1;
    display: block;
    margin: -1.8rem;
    padding-top: 100%;
    padding-bottom: 3.6rem;
    background: rgba(104, 192, 186, 0.5);
    content: '';
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    opacity: 0; }
  .m-product-tile__image:after {
    position: absolute;
    z-index: 0;
    top: 100%;
    right: 0;
    left: 0;
    height: 15.4rem;
    margin-top: 0.2rem;
    background: #68c0ba;
    content: '';
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    opacity: 0; }
  .m-product-tile__image > img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 85%;
    max-height: 85%;
    transform: translateX(-50%) translateY(-50%); }
  .m-product-tile__image > div:not(.m-product-tile__badge) {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    width: 90%;
    height: 5.6rem;
    line-height: 5.6rem;
    transform: translateX(-50%); }
    .m-product-tile__image > div:not(.m-product-tile__badge) > .m-link {
      width: 100%;
      text-align: center; }
      .m-product-tile__image > div:not(.m-product-tile__badge) > .m-link:hover {
        color: #fff; }
        .m-product-tile__image > div:not(.m-product-tile__badge) > .m-link:hover > i {
          color: #fff; }
      .m-product-tile__image > div:not(.m-product-tile__badge) > .m-link > i {
        display: inline-block; }
  .m-product-tile__image > i {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: 6rem;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    opacity: 0; }

.m-product-tile__image--clean:before {
  background: transparent;
  content: '';
  opacity: 1;
  box-shadow: inset 0 -5.6rem 0 rgba(104, 192, 186, 0.7); }

.m-product-tile__image--light-background {
  background: #f2f2f2; }

.m-product-tile__delete-button {
  position: absolute;
  z-index: 3;
  top: 1.2rem;
  right: 1.2rem; }

.m-product-tile__title {
  display: block;
  height: 5.6rem;
  margin-right: 1.2rem;
  margin-bottom: 1.4rem;
  margin-left: 1.2rem;
  text-transform: none;
  letter-spacing: 0;
  transition: color 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-product-tile__title.h--underlined:after {
    transition: width 0.6s cubic-bezier(0.55, 0, 0.1, 1), background 0.6s cubic-bezier(0.55, 0, 0.1, 1); }

.m-product-tile__artist {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  height: 2.8rem;
  color: #9a9a9a;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.1rem;
  text-overflow: ellipsis;
  transition: color 0.6s cubic-bezier(0.55, 0, 0.1, 1); }

.m-product-tile__price {
  position: relative;
  z-index: 1;
  display: block;
  padding-bottom: 1.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  transition: color 0.6s cubic-bezier(0.55, 0, 0.1, 1); }

.m-product-tile__badge {
  position: absolute;
  top: 2.1rem;
  left: 0;
  width: 7rem;
  height: 4.9rem;
  color: #fff;
  background: #db701d;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem; }
  .m-product-tile__badge > span {
    position: absolute;
    top: 50%;
    right: 0.2rem;
    left: 0.2rem;
    transform: translateY(-50%); }

.m-product-tile__badge--price {
  width: 12rem; }

.ie9 .m-product-tile__image {
  text-align: center !important; }
  .ie9 .m-product-tile__image:before {
    display: none; }

.ie9 .m-product-tile__title.h--underlined:after {
  display: none; }

.m-product-tile-skeleton {
  animation: pulse 2s infinite; }
  .m-product-tile-skeleton > i:first-of-type {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 1.8rem;
    background: #eaeaea;
    background: #eaeaea; }
    .m-product-tile-skeleton > i:first-of-type:before {
      position: relative;
      display: block;
      margin: -1.8rem;
      padding-top: 100%;
      padding-bottom: 3.6rem;
      content: ''; }
  .m-product-tile-skeleton > i:nth-of-type(2) {
    display: block;
    height: 15.4rem;
    background: rgba(234, 234, 234, 0.5); }

@keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.4; }
  100% {
    opacity: 1; } }

.m-profile-image {
  position: relative;
  width: 12.6rem;
  height: 12.6rem;
  border: 0.1rem solid #eaeaea;
  border-radius: 50%; }

.m-profile-image--large {
  width: 28rem;
  height: 28rem; }

.m-profile-image--full-width {
  width: 100%;
  height: 100%; }

.m-profile-image--bonus {
  width: 20rem;
  height: 20rem;
  border-width: 0.3rem;
  padding: 1rem; }
  .m-profile-image--bonus > img {
    width: 100%; }

.m-progress-bar > ol {
  display: table;
  width: 100%;
  list-style: none;
  table-layout: fixed;
  counter-reset: progress-bar; }
  .m-progress-bar > ol > li {
    position: relative;
    display: table-cell;
    height: 2.8rem;
    text-indent: -9999rem;
    vertical-align: middle;
    counter-increment: progress-bar;
    cursor: default; }
    @media (min-width: 1024px) {
      .m-progress-bar > ol > li {
        padding-left: 6.3rem;
        text-indent: 0; } }
    .m-progress-bar > ol > li:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4.9rem;
      height: 4.9rem;
      border: 0.2rem solid #eaeaea;
      border-radius: 50%;
      color: #9a9a9a;
      background: #fff;
      font-size: 2.4rem;
      line-height: 0;
      vertical-align: middle;
      padding-top: 2.3rem;
      text-align: center;
      text-indent: 0;
      content: counter(progress-bar);
      transform: translateX(-50%) translateY(-50%); }
      @media (min-width: 1024px) {
        .m-progress-bar > ol > li:before {
          left: 0;
          transform: translateY(-50%); } }
    .m-progress-bar > ol > li:after {
      position: absolute;
      z-index: -1;
      top: 50%;
      display: none;
      height: 0.2rem;
      background: #eaeaea;
      content: '';
      transform: translateY(-50%); }
      @media (min-width: 768px) {
        .m-progress-bar > ol > li:after {
          right: -30%;
          left: 70%;
          display: block; } }
      @media (min-width: 1024px) {
        .m-progress-bar > ol > li:after {
          right: 1.2rem;
          left: 50%; } }
    .m-progress-bar > ol > li:last-child:after {
      display: none; }
      @media (min-width: 1024px) {
        .m-progress-bar > ol > li:last-child:after {
          right: 0;
          display: block; } }
    .m-progress-bar > ol > li.active {
      color: #db701d; }
      .m-progress-bar > ol > li.active:before {
        border-color: #db701d;
        color: #db701d; }
    .m-progress-bar > ol > li.done {
      color: #68c0ba; }
      .m-progress-bar > ol > li.done:before {
        border-color: #68c0ba;
        color: #68c0ba;
        font-family: "strassacker-edition"; }
    .m-progress-bar > ol > li span {
      position: relative;
      display: inline-block;
      overflow: hidden;
      max-width: 100%;
      padding-right: 1.2rem;
      background: #fff;
      font-size: 1.3rem;
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: 0.1rem;
      text-overflow: ellipsis;
      vertical-align: middle; }

.ie9 .m-progress-bar > ol > li:before {
  top: 0;
  line-height: 1.8; }

.m-property-list {
  font-size: 1.6rem;
  list-style: none; }
  .m-property-list > li {
    margin-bottom: 2.8rem; }
    @media (min-width: 768px) {
      .m-property-list > li {
        margin-bottom: 1.4rem; } }
    @media (min-width: 1024px) {
      .m-property-list > li {
        margin-bottom: 2.8rem; } }
    .m-property-list > li:last-child {
      margin-bottom: 0; }
    .m-property-list > li > strong, .m-property-list > li > a {
      font-weight: 400; }
    .m-property-list > li > strong {
      display: block; }

.m-read-more {
  overflow-y: hidden;
  transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1); }

.m-read-more__trigger {
  transition: opacity 0.3s;
  opacity: 1; }
  .m-read-more__trigger.hidden {
    pointer-events: none;
    opacity: 0; }

.m-search-input {
  position: relative;
  width: 100%; }
  .m-search-input:before {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    color: #646464;
    font-family: "strassacker-edition";
    transform: translateY(-50%); }
  .m-search-input input[type=text] {
    padding-left: 4rem; }
    .m-search-input input[type=text] + .m-search-input__close-button {
      position: absolute;
      top: 50%;
      right: 1.2rem;
      color: #68c0ba;
      font-size: 1.6rem;
      line-height: 1.4rem;
      transition: color 0.3s;
      transform: translateY(-50%); }
      .m-search-input input[type=text] + .m-search-input__close-button:hover {
        color: #646464; }
      .m-search-input input[type=text] + .m-search-input__close-button > i {
        position: relative;
        top: 0.2rem; }
  .m-search-input .ico-spinner {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: #68c0ba;
    background: #eaeaea;
    line-height: 3.6rem;
    text-align: center;
    animation: spin 1.8s infinite linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.m-search-panel {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  overflow: hidden;
  max-height: 0;
  color: #646464;
  background: #fff;
  font-size: 1.5rem;
  line-height: 2.1rem;
  cursor: auto;
  transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1), box-shadow 0.6s; }
  .m-search-panel:after {
    content: " ";
    display: block;
    clear: both; }
  .m-search-panel.active {
    max-height: 50.4rem;
    border-bottom: none;
    box-shadow: 0 0.2rem 0.4rem rgba(58, 58, 58, 0.25); }

.m-search-panel__item {
  position: relative;
  float: left;
  display: block;
  width: 50%;
  height: 12rem;
  padding-top: 1.4rem;
  padding-right: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1.4rem;
  color: #646464;
  cursor: pointer;
  transition: background 0.3s cubic-bezier(0.55, 0, 0.1, 1), color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-search-panel__item:after {
    content: " ";
    display: block;
    clear: both; }
  .m-search-panel__item:hover {
    color: #fff;
    background: #68c0ba; }
    .m-search-panel__item:hover > header > strong {
      color: #fff; }
    .m-search-panel__item:hover > button {
      color: #fff; }
  .m-search-panel__item > header {
    float: left;
    padding-top: 1.4rem;
    padding-left: 1.2rem; }
    .m-search-panel__item > header > strong {
      display: block;
      color: #68c0ba;
      transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-search-panel__item > button {
    position: absolute;
    top: 50%;
    right: 1.2rem;
    color: #68c0ba;
    transition: color 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    transform: translateY(-50%); }

.m-search-panel__item-image {
  position: relative;
  float: left;
  display: inline-block;
  width: 9rem;
  height: 9rem;
  padding: 0.8rem;
  background: #eaeaea;
  font-size: 0;
  line-height: 0; }
  .m-search-panel__item-image > img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 7.4rem;
    max-height: 7.4rem;
    transform: translateX(-50%) translateY(-50%); }

.m-search-panel__search-link {
  clear: both;
  display: block;
  width: 100%;
  margin-top: 1.4rem; }
  .m-search-panel__search-link:hover {
    border-color: #48ada6;
    color: #fff;
    background: #48ada6; }

.m-separator {
  position: relative;
  display: block; }
  .m-separator:hover > .m-separator__label {
    padding-right: 2.4rem;
    padding-left: 2.4rem;
    color: #9a9a9a; }
    @media (min-width: 768px) {
      .m-separator:hover > .m-separator__label {
        padding-right: 4.8rem;
        padding-left: 4.8rem; } }
  .m-separator:last-child > hr {
    margin-bottom: 1.4rem; }
  .m-separator > hr {
    height: 0.2rem;
    margin-top: 1.4rem;
    margin-bottom: 2.8rem;
    border: none;
    background: #d1d1d1; }
  .m-separator > .m-separator__label {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    padding: 0 1.2rem;
    color: #68c0ba;
    background: #fff;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    transition: padding 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transform: translateX(-50%) translateY(-50%); }
    @media (min-width: 768px) {
      .m-separator > .m-separator__label {
        font-size: 1.5rem;
        letter-spacing: 0.05rem; } }
  .m-separator > .m-separator__label--large {
    font-size: 1.8rem;
    font-weight: 400; }

.m-separator--light {
  background: #fff; }

.m-separator--small {
  width: 6rem;
  margin-right: auto;
  margin-left: auto; }

.m-set-info {
  position: fixed;
  z-index: 5000;
  bottom: 2.8rem;
  left: -100%;
  display: block;
  width: 30rem;
  padding: 1.2rem 1.8rem;
  color: #fff;
  background: #68c0ba;
  font-size: 1.3rem;
  line-height: 1.4rem;
  transition: background 0.3s, left 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
  .m-set-info:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .m-set-info {
      width: 34rem;
      font-size: 1.6rem;
      line-height: 2.1rem; } }
  .m-set-info:hover {
    color: #fff;
    background: #48ada6; }
  .m-set-info.active {
    left: 0; }

.m-set-info__thumbnail {
  position: relative;
  float: left;
  width: 6rem;
  height: 6rem;
  background: #eaeaea;
  text-align: center; }
  @media (min-width: 768px) {
    .m-set-info__thumbnail {
      width: 10rem;
      height: 10rem; } }
  .m-set-info__thumbnail > img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 85%;
    max-height: 85%;
    transform: translateX(-50%) translateY(-50%); }

.m-set-info__body {
  position: relative;
  float: left;
  width: 20.4rem;
  height: 6rem; }
  @media (min-width: 768px) {
    .m-set-info__body {
      width: 20.4rem;
      height: 10rem; } }
  .m-set-info__body > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding-left: 1.2rem;
    transform: translateX(-50%) translateY(-50%); }
    .m-set-info__body > div > strong {
      display: block; }
      .m-set-info__body > div > strong:first-child {
        margin-bottom: 0.7rem; }
        @media (min-width: 768px) {
          .m-set-info__body > div > strong:first-child {
            margin-bottom: 1.4rem; } }

.m-signet > img {
  width: 6.3rem;
  height: 6.3rem;
  vertical-align: middle; }

.m-signet > div {
  display: inline-block;
  margin-top: 0.7rem;
  line-height: 2.1rem;
  vertical-align: middle; }
  @media (min-width: 768px) {
    .m-signet > div {
      margin-top: 0;
      padding-left: 1rem; } }
  .m-signet > div:first-child {
    margin-top: 0;
    margin-right: 1rem;
    padding-left: 0; }
  .m-signet > div strong {
    display: block; }

.m-signet--sticky {
  position: fixed;
  z-index: 4999;
  right: 0;
  bottom: 11.2rem;
  display: none;
  width: 72px;
  padding: 1.2rem;
  color: #646464;
  background: #fff;
  box-shadow: 0 0 0.5rem rgba(58, 58, 58, 0.5); }
  @media (min-width: 768px) {
    .m-signet--sticky {
      display: block; } }
  @media (min-width: 1200px) {
    .m-signet--sticky {
      width: 200px; } }
  .m-signet--sticky > img {
    width: 4.9rem;
    height: 4.9rem;
    vertical-align: middle; }
  .m-signet--sticky > div {
    display: none;
    width: 120px;
    font-size: 1.3rem;
    line-height: 1.68rem; }
    @media (min-width: 1200px) {
      .m-signet--sticky > div {
        display: inline-block; } }

.m-social-button {
  display: inline-block;
  width: 4.2rem;
  height: 4.2rem;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
  border: 0.2rem solid #3a3a3a;
  border-radius: 50%;
  line-height: 4.2rem;
  text-align: center;
  transition: background 0.3s, color 0.3s, border-color 0.3s; }
  .m-social-button.m-social-button--filled {
    border-color: #68c0ba;
    color: #fff;
    background: #68c0ba; }
    .m-social-button.m-social-button--filled:hover {
      border-color: #48ada6;
      background: #48ada6; }
  .m-social-button > i {
    position: relative;
    top: 0.2rem;
    font-size: 2.1rem; }

.m-social-button--facebook {
  border-color: #45619d;
  color: #45619d; }
  .m-social-button--facebook:hover {
    color: #fff;
    background: #45619d; }
  .m-social-button--facebook.m-social-button--filled {
    border-color: #45619d;
    color: #fff;
    background: #45619d; }
    .m-social-button--facebook.m-social-button--filled:hover {
      border-color: #354b7a;
      background: #354b7a; }

.m-social-button--twitter {
  border-color: #659fcb;
  color: #659fcb; }
  .m-social-button--twitter:hover {
    color: #fff;
    background: #659fcb; }
  .m-social-button--twitter.m-social-button--filled {
    border-color: #659fcb;
    color: #fff;
    background: #659fcb; }
    .m-social-button--twitter.m-social-button--filled:hover {
      border-color: #4087bd;
      background: #4087bd; }

.m-social-button--gplus {
  border-color: #e94a34;
  color: #e94a34; }
  .m-social-button--gplus:hover {
    color: #fff;
    background: #e94a34; }
  .m-social-button--gplus.m-social-button--filled {
    border-color: #e94a34;
    color: #fff;
    background: #e94a34; }
    .m-social-button--gplus.m-social-button--filled:hover {
      border-color: #d32e17;
      background: #d32e17; }

.m-social-button--pinterest {
  border-color: #cb2028;
  color: #cb2028; }
  .m-social-button--pinterest:hover {
    color: #fff;
    background: #cb2028; }
  .m-social-button--pinterest.m-social-button--filled {
    border-color: #cb2028;
    color: #fff;
    background: #cb2028; }
    .m-social-button--pinterest.m-social-button--filled:hover {
      border-color: #9f191f;
      background: #9f191f; }

.m-stage-skeleton {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff; }
  .m-stage-skeleton:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .m-stage-skeleton {
      height: 27.3rem; } }
  @media (min-width: 1024px) {
    .m-stage-skeleton {
      height: 49rem; } }

.m-stage-skeleton__left-panel {
  position: relative;
  height: 23.7rem;
  background: #ededed;
  animation: pulse 2s infinite; }
  @media (min-width: 768px) {
    .m-stage-skeleton__left-panel {
      height: 27.3rem;
      width: 25%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  @media (min-width: 1024px) {
    .m-stage-skeleton__left-panel {
      height: 49rem; } }

.m-stage-skeleton__right-panel {
  height: auto;
  background: #f9f9f9;
  animation: pulse 2s infinite; }
  .m-stage-skeleton__right-panel:before {
    display: block;
    padding-top: 56.25%;
    content: ''; }
  @media (min-width: 768px) {
    .m-stage-skeleton__right-panel {
      height: 27.3rem;
      width: 75%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; }
      .m-stage-skeleton__right-panel:before {
        display: none; } }
  @media (min-width: 1024px) {
    .m-stage-skeleton__right-panel {
      height: 49rem; } }

.m-stage-skeleton__nav {
  position: relative;
  float: left;
  width: 100%;
  height: 2.8rem;
  margin-top: 1.4rem;
  background: #f9f9f9;
  animation: pulse 2s infinite; }

@keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.4; }
  100% {
    opacity: 1; } }

.m-tab-nav {
  list-style: none; }
  .m-tab-nav:after {
    content: " ";
    display: block;
    clear: both; }
  .m-tab-nav > li {
    float: left;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
    @media (min-width: 768px) {
      .m-tab-nav > li {
        padding-right: 1rem;
        padding-left: 1rem; } }
    .m-tab-nav > li:first-child {
      padding-left: 0; }
    .m-tab-nav > li:last-child {
      padding-right: 0; }
    .m-tab-nav > li.active {
      position: relative; }
      .m-tab-nav > li.active:after {
        position: absolute;
        top: 100%;
        left: 50%;
        content: '';
        transform: translateX(-50%);
        border: 0.8rem transparent solid;
        border-top-color: #9a9a9a; }
      .m-tab-nav > li.active > a {
        border-color: #9a9a9a;
        color: #fff;
        background: #9a9a9a; }
        .m-tab-nav > li.active > a:hover {
          border-color: #9a9a9a;
          color: #fff;
          background: #9a9a9a; }
    .m-tab-nav > li > a {
      position: relative;
      display: block;
      overflow: hidden;
      width: 100%;
      min-height: 5.6rem;
      padding: 1.2rem 0.4rem;
      border: 0.2rem solid #68c0ba;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.4rem;
      text-align: center;
      transition: background 0.3s, color 0.3s; }
      @media (min-width: 768px) {
        .m-tab-nav > li > a {
          min-height: 0;
          padding: 1.2rem 1.8rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
          text-transform: uppercase;
          white-space: nowrap;
          letter-spacing: 0.1rem;
          text-overflow: ellipsis; } }
      .m-tab-nav > li > a:hover {
        color: #fff;
        background: #68c0ba; }
      .m-tab-nav > li > a > span {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 100%;
        transform: translateX(-50%) translateY(-50%); }
        @media (min-width: 768px) {
          .m-tab-nav > li > a > span {
            position: relative;
            top: auto;
            left: auto;
            transform: translateX(0) translateY(0); } }

.m-tabs__nav > ul {
  display: table;
  width: 100.1%;
  list-style: none;
  table-layout: fixed; }
  .m-tabs__nav > ul > li {
    display: table-cell;
    padding: 1.4rem 1.2rem 1.2rem;
    border: 0.8rem solid #fff;
    border-top: none;
    color: #68c0ba;
    background: #eaeaea;
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    transition: border 0.3s, color 0.3s, background 0.3s; }
    .m-tabs__nav > ul > li.active {
      padding-bottom: 2rem;
      border: none;
      color: #fff;
      background: #646464; }

.m-tabs__tab {
  padding-bottom: 2.8rem; }

.m-top-button {
  position: fixed;
  z-index: 5000;
  right: 0;
  bottom: 2.8rem;
  display: none;
  width: 4.9rem;
  height: 4.9rem;
  color: #fff;
  background: #68c0ba;
  line-height: 4.9rem;
  transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), background 0.3s;
  opacity: 0; }
  @media (min-width: 1024px) {
    .m-top-button {
      display: block; } }
  .m-top-button:hover {
    background: #48ada6; }
  .m-top-button > i {
    position: relative;
    top: 0.2rem;
    left: 0.1rem; }

.m-tree-menu {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  font-size: 1.5rem;
  -webkit-overflow-scrolling: auto; }

.m-tree-menu__level {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  background: #fff;
  transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateX(100%); }
  .m-tree-menu__level.active {
    transform: translateX(0); }

.m-tree-menu__root-level > .m-tree-menu__level {
  transform: translateX(0); }

.m-tree-menu__root-level > .m-tree-menu__level > .m-tree-menu__header > strong {
  left: 1.8rem;
  padding-left: 0;
  text-align: left;
  transform: translateY(-50%); }

.m-tree-menu__root-level > .m-tree-menu__level > .m-tree-menu__header > .m-tree-menu__back-button {
  display: none; }

.m-tree-menu__header {
  position: relative;
  padding: 1.8rem 1.8rem;
  border-bottom: 0.2rem solid #eaeaea;
  text-align: center; }
  .m-tree-menu__header:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 480px) {
    .m-tree-menu__header {
      padding: 0.9rem 1.8rem; } }
  @media (min-width: 768px) {
    .m-tree-menu__header {
      padding: 1.8rem 1.8rem; } }
  .m-tree-menu__header > strong {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    padding-right: 4.2rem;
    padding-left: 4rem;
    font-weight: 400;
    line-height: 2.1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none; }

.m-tree-menu__nav {
  overflow-y: hidden;
  height: 100%; }
  .m-tree-menu__nav a:not(.m-tree-menu__close-button):not(.m-tree-menu__back-button),
  .m-tree-menu__nav button:not(.m-tree-menu__close-button):not(.m-tree-menu__back-button) {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.9rem 1.8rem;
    color: #68c0ba;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.8rem;
    text-align: left;
    vertical-align: baseline; }
    .m-tree-menu__nav a:not(.m-tree-menu__close-button):not(.m-tree-menu__back-button):hover,
    .m-tree-menu__nav button:not(.m-tree-menu__close-button):not(.m-tree-menu__back-button):hover {
      color: #419b95; }
    .m-tree-menu__nav a:not(.m-tree-menu__close-button):not(.m-tree-menu__back-button) > i,
    .m-tree-menu__nav button:not(.m-tree-menu__close-button):not(.m-tree-menu__back-button) > i {
      position: absolute;
      top: 50%;
      right: 1.8rem;
      transform: translateY(-50%); }

.m-tree-menu__close-button {
  position: relative;
  top: 0.2rem;
  float: right;
  color: #68c0ba; }
  .m-tree-menu__close-button:hover {
    color: #419b95; }

.m-tree-menu__back-button {
  position: relative;
  top: 0.2rem;
  float: left;
  color: #68c0ba; }
  .m-tree-menu__back-button:hover {
    color: #419b95; }

.m-tree-menu__sec-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 17rem;
  padding: 2.7rem 1.8rem 1.8rem;
  border-top: 0.5rem solid #db701d;
  background: #eaeaea; }
  @media (min-width: 480px) {
    .m-tree-menu__sec-nav {
      height: 11rem;
      padding-top: 1.8rem;
      padding-bottom: 0.9rem; } }
  @media (min-width: 768px) {
    .m-tree-menu__sec-nav {
      height: 17rem;
      padding-top: 2.7rem;
      padding-bottom: 1.8rem; } }
  .m-tree-menu__sec-nav:after {
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    height: 4.2rem;
    margin-bottom: 0.5rem;
    background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    pointer-events: none; }
  .m-tree-menu__sec-nav .m-nav-bar {
    width: 100%;
    margin-bottom: 1.4rem; }
    @media (min-width: 480px) {
      .m-tree-menu__sec-nav .m-nav-bar {
        margin-bottom: 0.7rem; } }
    @media (min-width: 768px) {
      .m-tree-menu__sec-nav .m-nav-bar {
        margin-bottom: 1.4rem; } }
    .m-tree-menu__sec-nav .m-nav-bar > li {
      width: 33.3333333333%;
      margin-right: 0;
      text-align: center; }
      .m-tree-menu__sec-nav .m-nav-bar > li > a {
        font-size: 1.2rem;
        text-align: center;
        letter-spacing: 0; }
        .m-tree-menu__sec-nav .m-nav-bar > li > a > i {
          font-size: 2.4rem; }
          @media (min-width: 480px) {
            .m-tree-menu__sec-nav .m-nav-bar > li > a > i {
              display: inline-block; } }
          @media (min-width: 768px) {
            .m-tree-menu__sec-nav .m-nav-bar > li > a > i {
              display: block; } }
  .m-tree-menu__sec-nav .m-search-input > input {
    border: none; }

.m-youtube {
  width: 100%;
  max-width: 64rem; }

.l-account .m-tab-nav > li {
  width: 33.3333333333%; }

.l-artist-application h2,
.l-artist-application .h2 {
  text-align: left; }

.l-carousel {
  position: relative;
  overflow: auto;
  margin-right: -1rem;
  margin-left: -1rem;
  font-size: 0;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .l-carousel {
      overflow: hidden;
      padding-bottom: 5.6rem; } }
  .l-carousel .m-dot-indicator {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: none;
    height: 2.1rem;
    transform: translateX(-50%); }
    @media (min-width: 768px) {
      .l-carousel .m-dot-indicator {
        display: block; } }

.l-carousel__slide {
  display: inline-block;
  width: 80%;
  padding: 0 1rem;
  white-space: normal;
  text-indent: 0;
  vertical-align: top; }
  @media (min-width: 768px) {
    .l-carousel__slide {
      width: 33.3333333333%; } }
  @media (min-width: 768px) {
    .l-carousel__slide {
      margin-bottom: 1.4rem; } }
  .l-carousel__slide .l-stage__slide-info > div {
    left: 65%;
    transition: left 1s cubic-bezier(0.55, 0, 0.1, 1), opacity 1s cubic-bezier(0.55, 0, 0.1, 1);
    opacity: 0; }
  .l-carousel__slide .l-stage__catalog-image {
    right: -5%;
    transition: right 1s cubic-bezier(0.55, 0, 0.1, 1), opacity 1s cubic-bezier(0.55, 0, 0.1, 1);
    opacity: 0; }
  .l-carousel__slide.active .l-stage__slide-info > div {
    left: 50%;
    opacity: 1; }
  .l-carousel__slide.active .l-stage__catalog-image {
    right: 2.4rem;
    opacity: 1; }

.l-carousel__slide--full-width {
  width: 100%; }

.l-carousel__nav-button {
  position: absolute;
  bottom: 0;
  display: none;
  color: #9a9a9a; }
  @media (min-width: 768px) {
    .l-carousel__nav-button {
      display: inline-block; } }
  .l-carousel__nav-button:hover {
    color: #68c0ba; }

.l-carousel__nav-button--next {
  right: 1rem; }

.l-carousel__nav-button--prev {
  left: 1rem; }

.l-cart-panel {
  overflow: hidden;
  font-size: 1.5rem; }

.l-cart-panel__header {
  position: relative;
  z-index: 2;
  padding: 1.8rem 1.8rem;
  border-bottom: 0.2rem solid #eaeaea;
  background: #fff;
  text-align: left; }
  .l-cart-panel__header:after {
    content: " ";
    display: block;
    clear: both; }
  .l-cart-panel__header > strong {
    position: absolute;
    top: 50%;
    left: 1.8rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    transform: translateY(-50%); }

.l-cart-panel__body {
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100vh;
  padding-right: 1.8rem;
  padding-left: 1.8rem;
  padding-top: 8.4rem;
  padding-bottom: 16rem; }

.l-cart-panel__item {
  margin-bottom: 1.4rem;
  color: #646464;
  line-height: 2.1rem; }
  .l-cart-panel__item:after {
    content: " ";
    display: block;
    clear: both; }

.l-cart-panel__item-image {
  position: relative;
  float: left;
  width: 25%;
  height: 7.1rem;
  padding: 0.4rem;
  background: #eaeaea;
  text-align: center; }
  .l-cart-panel__item-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 80%;
    max-height: 6.3rem;
    transform: translateX(-50%) translateY(-50%); }

.l-cart-panel__item-body {
  position: relative;
  float: left;
  width: 75%;
  padding-top: 0.4rem;
  padding-left: 1.2rem; }
  .l-cart-panel__item-body .delete-button {
    position: absolute;
    z-index: 1;
    top: 0;
    right: -0.4rem;
    padding: 0.4rem;
    color: #9a9a9a;
    font-size: 1.3rem; }

.l-cart-panel__sum {
  font-size: 1.6rem; }

.l-cart-panel__footer {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  left: 0;
  height: 16rem;
  padding: 2.7rem 1.8rem 1.8rem;
  border-top: 0.5rem solid #db701d;
  background: #eaeaea; }
  .l-cart-panel__footer:after {
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    height: 4.2rem;
    margin-bottom: 0.5rem;
    background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    pointer-events: none; }
  .l-cart-panel__footer .m-button {
    width: 100%; }

.l-cart-panel__message {
  position: relative;
  overflow: hidden;
  max-height: 0;
  color: #fff;
  background: #68c0ba;
  font-weight: 400;
  line-height: 2.1rem;
  transition: max-height 0.6s cubic-bezier(0.55, 0, 0.1, 1), opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), margin-bottom 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0; }
  .l-cart-panel__message.active {
    max-height: 11.2rem;
    margin-bottom: 1.4rem;
    opacity: 1; }
  .l-cart-panel__message:before {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    margin-top: -0.1rem;
    font-family: "strassacker-edition";
    font-size: 1.8rem;
    transform: translateY(-50%); }
  .l-cart-panel__message > div {
    padding: 1.2rem 1.2rem 1.2rem 4rem; }

.l-contact {
  margin-bottom: 50px !important; }

.l-catalogs__box {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 1024px) {
    .l-catalogs__box {
      width: 50%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-catalogs__box img {
    max-width: 100%; }
  .l-catalogs__box h3 {
    margin-top: 0.7rem; }
  .l-catalogs__box .m-button {
    margin-bottom: 1.4rem; }
  .l-catalogs__box .m-box {
    position: relative; }
    .l-catalogs__box .m-box:nth-child(2n+1) {
      margin-bottom: 0; }

.l-catalogs__box--narrow {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 1024px) {
    .l-catalogs__box--narrow {
      width: 33.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  @media (min-width: 1024px) {
    .l-catalogs__box--narrow .l-catalogs__box-image,
    .l-catalogs__box--narrow .l-catalogs__box-info {
      width: 100%; } }
  .l-catalogs__box--narrow .l-catalogs__box-image > img {
    height: 28rem; }

.l-catalogs__box-badge {
  position: absolute;
  top: 2.1rem;
  left: 0;
  width: 7rem;
  height: 4.9rem;
  color: #fff;
  background: #db701d;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: center; }
  .l-catalogs__box-badge > span {
    position: absolute;
    top: 50%;
    right: 0.2rem;
    left: 0.2rem;
    transform: translateY(-50%); }

.l-checkout .m-progress-bar {
  margin-bottom: 4.2rem; }

.l-checkout .m-loading-spinner {
  margin-top: 11.2rem;
  margin-bottom: 11.2rem; }

.l-checkout .m-box .m-button {
  margin-bottom: 0.7rem; }
  @media (min-width: 1024px) {
    .l-checkout .m-box .m-button {
      margin-right: 0.4rem;
      margin-left: 0.4rem; } }

.l-checkout__tools {
  min-width: 16em; }
  .l-checkout__tools:first-of-type {
    display: none; }
    @media (min-width: 1024px) {
      .l-checkout__tools:first-of-type {
        display: block; } }
  .l-checkout__tools .m-box {
    padding: 1.8rem; }
    .l-checkout__tools .m-box:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 1024px) {
      .l-checkout__tools .m-box {
        text-align: left; } }
    .l-checkout__tools .m-box aside {
      text-align: center; }
      @media (min-width: 1024px) {
        .l-checkout__tools .m-box aside {
          float: right;
          max-width: 40%;
          margin-right: 2rem;
          line-height: 2.1rem;
          text-align: right; } }
    .l-checkout__tools .m-box .m-button {
      width: 100%;
      margin-bottom: 0.7rem;
      white-space: normal; }
      @media (min-width: 1024px) {
        .l-checkout__tools .m-box .m-button {
          width: auto;
          margin-bottom: 0; } }
    .l-checkout__tools .m-box .m-link {
      margin-bottom: 0.7rem;
      font-size: 1.3rem;
      text-transform: none;
      letter-spacing: 0; }
      @media (min-width: 1024px) {
        .l-checkout__tools .m-box .m-link {
          margin-bottom: 0;
          margin-left: 2rem; } }
      .l-checkout__tools .m-box .m-link > i {
        display: inline-block;
        font-size: 1.8rem; }

.l-checkout__tools.always {
  display: block; }

.l-checkout__summary {
  position: relative;
  margin-bottom: 2.8rem;
  padding-right: 1.8rem;
  padding-left: 1.8rem;
  background: #eaeaea; }
  .l-checkout__summary:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 1024px) {
    .l-checkout__summary {
      padding-right: 2.4rem;
      padding-left: 2.4rem; } }
  .l-checkout__summary > div {
    float: left;
    width: 100%;
    height: 100%;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    text-align: left;
    vertical-align: middle; }
    @media (min-width: 1024px) {
      .l-checkout__summary > div {
        width: 50%; } }
    .l-checkout__summary > div:first-child {
      display: none;
      padding-top: 4.2rem; }
      @media (min-width: 1024px) {
        .l-checkout__summary > div:first-child {
          display: block; } }
      .l-checkout__summary > div:first-child.with-input {
        display: block;
        padding-top: 2.8rem;
        padding-bottom: 0; }
        @media (min-width: 1024px) {
          .l-checkout__summary > div:first-child.with-input {
            padding-right: 2rem;
            padding-bottom: 1.4rem; } }
        .l-checkout__summary > div:first-child.with-input textarea {
          width: 100%;
          height: 19rem; }
  .l-checkout__summary .m-description-list {
    font-size: 1.6rem; }

@media (min-width: 1024px) {
  .l-checkout__mobile-checkout-button {
    display: none; } }

.container-flex-1 {
  display: flex; }

.l-checkout__address-box {
  position: relative;
  height: 100%; }
  .l-checkout__address-box em {
    display: block;
    margin-top: 8.4rem; }
  .l-checkout__address-box .m-link {
    position: absolute;
    right: 1.8rem;
    bottom: 1.8rem; }
    .l-checkout__address-box .m-link > i {
      position: relative;
      top: -2.1rem;
      margin-right: 0;
      font-size: 1.8rem; }
      @media (min-width: 1024px) {
        .l-checkout__address-box .m-link > i {
          top: -0.6rem; } }

.l-checkbox__placeholder {
  min-height: 30vh;
  padding-top: 5.6rem; }

.l-content {
  min-height: 50vh;
  transition: padding-top 0.6s cubic-bezier(0.55, 0, 0.1, 1); }

.l-content__header {
  position: relative;
  overflow: hidden;
  max-width: 96rem;
  margin-top: 4.2rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center; }
  @media (min-width: 768px) {
    .l-content__header {
      margin-top: 5.6rem; } }
  .l-content__header:last-child {
    margin-bottom: 0; }
  .l-content__header > aside {
    margin-bottom: 2.8rem;
    font-size: 1.6rem; }
    .l-content__header > aside:last-child {
      margin-bottom: 0; }
  .l-content__header .m-profile-image {
    margin-bottom: 1.4rem; }
    @media (min-width: 768px) {
      .l-content__header .m-profile-image {
        margin-bottom: 0; } }
  .l-content__header .m-signature-image {
    display: block;
    margin-right: auto;
    margin-bottom: 2.8rem;
    margin-left: auto; }
    @media (min-width: 768px) {
      .l-content__header .m-signature-image {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline;
        margin-top: 2.8rem;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 30rem;
        transform: translateX(-50%) translateY(-50%); } }

.l-content__sidebar {
  margin-bottom: 2.8rem;
  padding-right: 2rem;
  padding-left: 2rem; }
  @media (min-width: 1024px) {
    .l-content__sidebar {
      padding-left: 1rem; } }
  .l-content__sidebar a {
    font-size: 1.6rem;
    font-weight: 400; }
    .l-content__sidebar a > i {
      position: relative;
      top: 0.3rem; }

.l-content__section:first-of-type:not(.l-content__section--medium):not(.l-content__section--dark):not(.l-content__section--span-mobile):not(.l-content__section--force-padding) > div {
  padding-top: 0; }

.l-content__section > div {
  max-width: 120rem;
  margin-right: auto;
  margin-left: auto;
  padding: 2.8rem 1rem; }
  .l-content__section > div:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .l-content__section > div {
      padding: 8.4rem 1rem; } }
  .l-content__section > div p {
    margin-bottom: 2.8rem; }
    .l-content__section > div p:last-child {
      margin-bottom: 0; }
  .l-content__section > div .condensed {
    max-width: 96rem;
    margin-right: auto;
    margin-left: auto;
    text-align: center; }
    .l-content__section > div .condensed .l-products__tiles {
      margin-right: 0 !important;
      margin-left: 0 !important; }
      .l-content__section > div .condensed .l-products__tiles > div {
        margin-right: -1rem;
        margin-left: -1rem; }
  .l-content__section > div .condensed--narrow {
    max-width: 64rem; }
  .l-content__section > div .m-article-teaser {
    margin-bottom: 2.8rem; }
    @media (min-width: 768px) {
      .l-content__section > div .m-article-teaser {
        margin-bottom: 0; } }

.l-content__section--medium {
  background: #eaeaea; }
  .l-content__section--medium > div {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: center; }
    @media (min-width: 768px) {
      .l-content__section--medium > div {
        padding: 4.2rem 1rem; } }
    .l-content__section--medium > div .container {
      margin-right: 0 !important;
      margin-left: 0 !important; }
      @media (min-width: 768px) {
        .l-content__section--medium > div .container {
          margin-right: -1rem !important;
          margin-left: -1rem !important; } }
  .l-content__section--medium .h--underlined:after {
    background: #9a9a9a; }
  .l-content__section--medium .m-separator__label {
    background: #eaeaea; }
  .l-content__section--medium fieldset:first-child {
    padding-top: 0; }
    .l-content__section--medium fieldset:first-child > legend {
      margin-top: 1.8rem; }

.l-content__section--dark {
  background: #646464; }
  .l-content__section--dark > div {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    color: #fff;
    text-align: center; }
    @media (min-width: 768px) {
      .l-content__section--dark > div {
        padding: 4.2rem 1rem; } }

.l-content__section--span-mobile > div {
  padding: 0; }
  @media (min-width: 768px) {
    .l-content__section--span-mobile > div {
      padding: 3rem 1rem 4.2rem; } }

.l-content__section--squeezed > div {
  padding-bottom: 0; }

.l-content__section--seo ul, .l-content__section--seo ol {
  list-style-position: inside; }

.l-content__module {
  margin-bottom: 4.2rem; }
  @media (min-width: 768px) {
    .l-content__module {
      margin-bottom: 8.4rem; } }
  .l-content__module:last-child {
    margin-bottom: 0; }

.l-contextual-navigation {
  position: relative;
  top: 0;
  margin-top: 1.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  transition: top 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .l-contextual-navigation:after {
    content: " ";
    display: block;
    clear: both; }
  .l-contextual-navigation .m-breadcrumbs {
    float: left; }

.l-contextual-navigation__back-button {
  position: relative;
  top: -0.2rem;
  float: right;
  display: none; }
  @media (min-width: 1024px) {
    .l-contextual-navigation__back-button {
      display: inline; } }
  .l-contextual-navigation__back-button > i {
    font-size: 1.3rem; }
    .l-contextual-navigation__back-button > i.ico-lock {
      font-size: 1.8rem; }

.l-event__social-buttons {
  margin-bottom: 2.8rem; }

.l-event__body img {
  max-width: 100%; }

.l-event__body p {
  text-align: left; }

.l-event__map {
  position: relative;
  display: block;
  width: 100%;
  height: 39.2rem; }
  .l-event__map > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%; }
  .l-event__map .info {
    position: relative;
    display: block;
    width: 96rem;
    max-width: 100%;
    height: 39.2rem;
    margin-right: auto;
    margin-left: auto;
    pointer-events: none; }
    .l-event__map .info .m-box {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      transform: translateX(-50%) translateY(-50%);
      pointer-events: auto; }
      @media (min-width: 768px) {
        .l-event__map .info .m-box {
          left: 0;
          width: 38rem;
          transform: translateY(-50%); } }
      .l-event__map .info .m-box > span {
        color: #9a9a9a;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 2.1rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem; }
      .l-event__map .info .m-box > strong {
        display: block; }
      .l-event__map .info .m-box > p {
        margin-bottom: 1.4rem;
        text-align: center; }

.l-footer {
  width: 100%;
  color: #fff;
  background: #3a3a3a;
  font-size: 1.6rem; }
  .l-footer a {
    color: #9a9a9a; }

.l-footer__sitemap {
  padding-top: 2.8rem;
  padding-right: 1rem;
  padding-bottom: 2.8rem;
  padding-left: 1rem;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .l-footer__sitemap:after {
    content: " ";
    display: block;
    clear: both; }
  .l-footer__sitemap > .m-link-list {
    padding-bottom: 1.4rem; }
    @media (min-width: 768px) {
      .l-footer__sitemap > .m-link-list {
        padding-bottom: 0;
        width: 25%;
        float: left;
        padding-left: 0.8333333333%;
        padding-right: 0.8333333333%; } }
    .l-footer__sitemap > .m-link-list:last-child {
      padding-bottom: 0; }
    .l-footer__sitemap > .m-link-list a {
      color: #9a9a9a; }
    .l-footer__sitemap > .m-link-list > strong {
      padding-bottom: 0;
      border-bottom: none; }
      @media (min-width: 768px) {
        .l-footer__sitemap > .m-link-list > strong {
          padding-bottom: 0.7rem;
          border-bottom: 0.2rem solid rgba(154, 154, 154, 0.25); } }

.l-footer__newsletter {
  padding: 4.2rem 1rem;
  background: #646464; }
  .l-footer__newsletter .m-newsletter-form {
    width: 80%;
    max-width: 64rem;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 1024px) {
      .l-footer__newsletter .m-newsletter-form {
        width: 60%; } }

.l-footer__newsletter-bonus {
  position: relative;
  width: 80%;
  max-width: 64rem;
  margin-top: 2.8rem;
  margin-right: auto;
  margin-left: auto;
  padding: 2.4rem;
  font-size: 0; }
  @media (min-width: 1024px) {
    .l-footer__newsletter-bonus {
      width: 60%; } }
  @media (min-width: 768px) {
    .l-footer__newsletter-bonus > div {
      display: inline-block;
      vertical-align: middle; } }
  .l-footer__newsletter-bonus > div:first-child {
    margin-bottom: 2.8rem; }
    @media (min-width: 768px) {
      .l-footer__newsletter-bonus > div:first-child {
        width: 33.3333333333%;
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .l-footer__newsletter-bonus > div:last-child {
      width: 66.6666666667%;
      padding-right: 10px;
      padding-left: 20px; } }
  .l-footer__newsletter-bonus > div > img {
    width: 75%;
    max-width: 14rem;
    vertical-align: middle; }
  .l-footer__newsletter-bonus > div > p {
    font-size: 1.8rem;
    line-height: 2.8rem;
    text-align: center; }
    @media (min-width: 768px) {
      .l-footer__newsletter-bonus > div > p {
        font-size: 2.4rem;
        line-height: 3.5rem; } }

.l-footer__meta {
  padding-top: 2.8rem;
  padding-right: 1rem;
  padding-bottom: 2.8rem;
  padding-left: 1rem;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .l-footer__meta:after {
    content: " ";
    display: block;
    clear: both; }
  .l-footer__meta > div {
    width: 100%;
    margin-bottom: 4.2rem;
    text-align: left; }
    @media (min-width: 768px) {
      .l-footer__meta > div {
        width: 50%;
        float: left;
        padding-left: 0.8333333333%;
        padding-right: 0.8333333333%; } }
    @media (min-width: 1024px) {
      .l-footer__meta > div {
        margin-bottom: 0;
        width: 25%;
        float: left;
        padding-left: 0.8333333333%;
        padding-right: 0.8333333333%; } }
    @media (min-width: 768px) {
      .l-footer__meta > div:first-child {
        width: 100%; } }
    @media (min-width: 1024px) {
      .l-footer__meta > div:first-child {
        width: 50%;
        float: left;
        padding-left: 0.8333333333%;
        padding-right: 0.8333333333%; } }
    .l-footer__meta > div:last-child {
      margin-top: 2.8rem;
      margin-bottom: 0; }
      @media (min-width: 768px) {
        .l-footer__meta > div:last-child {
          width: 100%;
          margin-top: 0; } }
      @media (min-width: 1024px) {
        .l-footer__meta > div:last-child {
          margin-top: 2.8rem;
          width: 100%;
          float: left;
          padding-left: 0.8333333333%;
          padding-right: 0.8333333333%; } }
    .l-footer__meta > div .h4 {
      text-align: left; }
    .l-footer__meta > div .m-phone-number > strong {
      font-size: 2rem; }
      @media (min-width: 1200px) {
        .l-footer__meta > div .m-phone-number > strong {
          font-size: 2.4rem; } }
    .l-footer__meta > div p {
      margin-bottom: 2.8rem; }
    .l-footer__meta > div a {
      color: #9a9a9a; }
    .l-footer__meta > div small {
      line-height: 2.1rem; }

.l-footer__cookie-button {
  position: fixed;
  bottom: 22px;
  right: 0;
  width: 60px;
  color: #9a9a9a;
  height: 60px;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 0.5rem rgba(58, 58, 58, 0.5);
  cursor: pointer;
  z-index: 9997; }
  .l-footer__cookie-button:hover {
    color: #48ada6 !important;
    transition: color 0.5s cubic-bezier(0.55, 0, 0.1, 1); }
  .l-footer__cookie-button > i {
    position: absolute;
    top: 10px;
    left: 21px; }
  .l-footer__cookie-button > span {
    font-size: 13px;
    line-height: 85px;
    font-weight: 700; }

.l-gallery {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding-bottom: 11.2rem; }

.l-gallery--active-360 {
  background: linear-gradient(to top, #e7e7e7 15%, #f1f1f1 33%, #feffff 60%, #fff 100%); }

.l-gallery__current-image {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: transform 0.6s, opacity 0.3s;
  opacity: 1; }
  .l-gallery__current-image.hidden {
    opacity: 0; }
  .l-gallery__current-image.panning {
    cursor: move; }

.l-gallery__nav {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 11.2rem;
  padding: 1.3rem 0 1.4rem;
  border-top: 0.2rem solid #eaeaea;
  background: #eaeaea;
  box-shadow: 0 0 1.4rem rgba(58, 58, 58, 0.1); }
  .l-gallery__nav:after {
    content: " ";
    display: block;
    clear: both; }

.l-gallery__thumbnails {
  display: none;
  overflow-x: auto;
  white-space: nowrap;
  width: 33.3333333333%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 1024px) {
    .l-gallery__thumbnails {
      display: block; } }
  .l-gallery__thumbnails div {
    position: relative;
    display: inline-block;
    width: 8.4rem;
    height: 8.4rem;
    margin-top: 0.1rem;
    margin-right: 1.2rem;
    background: #fff;
    cursor: pointer; }
    .l-gallery__thumbnails div:hover {
      outline: 0.1rem solid #68c0ba; }
    .l-gallery__thumbnails div.selected {
      outline: 0.1rem solid #68c0ba; }
    .l-gallery__thumbnails div img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 90%;
      max-height: 90%;
      transform: translateX(-50%) translateY(-50%); }

.l-gallery__info {
  padding-top: 1.4rem;
  text-align: center;
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 1024px) {
    .l-gallery__info {
      width: 33.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-gallery__info .l-product__artist {
    display: block;
    color: #9a9a9a;
    line-height: 2.1rem; }
  .l-gallery__info > header {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    padding-top: 0.7rem;
    font-size: 1.3rem;
    transform: translateX(-50%) translateY(-50%); }
    @media (min-width: 768px) {
      .l-gallery__info > header {
        width: auto;
        font-size: 1.8rem; } }
    .l-gallery__info > header .h2 {
      margin-bottom: 0;
      font-size: 1.6rem;
      line-height: 2.1rem; }
      @media (min-width: 768px) {
        .l-gallery__info > header .h2 {
          font-size: 2.4rem;
          line-height: 2.8rem; } }

.l-gallery__controls {
  display: none;
  line-height: 5.6rem;
  text-align: right; }
  @media (min-width: 768px) {
    .l-gallery__controls {
      display: block;
      width: 100%;
      padding-right: 1.8rem; } }
  @media (min-width: 1024px) {
    .l-gallery__controls {
      padding-right: 0;
      line-height: 8.4rem;
      width: 33.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-gallery__controls .m-button {
    width: 5.6rem;
    min-width: 0;
    height: 5.6rem;
    margin-right: 0.4rem;
    padding-right: 0;
    padding-left: 0;
    line-height: 3.2rem;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle; }
    .l-gallery__controls .m-button:last-child {
      margin-right: 0; }

.l-gallery__back-button,
.l-gallery__next-button {
  position: absolute;
  top: 50%;
  margin-top: -5.6rem;
  color: #68c0ba;
  font-size: 2.4rem;
  line-height: 5.6rem;
  transform: translateY(-50%); }
  .l-gallery__back-button:hover,
  .l-gallery__next-button:hover {
    color: #48ada6; }

.l-gallery__back-button {
  left: 1.4rem; }

.l-gallery__next-button {
  right: 1.4rem; }

.l-header {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 1.4rem;
  border-bottom: 0.2rem solid #eaeaea;
  background: #fff;
  transition: top 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
  @media (min-width: 1024px) {
    .l-header {
      position: relative;
      height: 21rem;
      padding-top: 2.8rem; } }
  .l-header > div {
    position: relative;
    height: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto; }
    .l-header > div:after {
      content: " ";
      display: block;
      clear: both; }

.l-header__logo {
  display: block;
  max-width: 180px;
  width: 100%;
  height: 4.48rem;
  margin-right: auto;
  margin-bottom: 1.4rem;
  margin-left: auto;
  text-align: center; }
  .l-header__logo > img {
    margin-top: 0;
    max-height: 100%; }
  @media (min-width: 768px) {
    .l-header__logo {
      height: 5.6rem;
      margin-right: 0;
      margin-left: 0;
      text-align: left; }
      .l-header__logo > img {
        width: 140px;
        margin-left: 5px;
        margin-top: 10px; } }
  @media (min-width: 1024px) {
    .l-header__logo {
      display: inline-block;
      height: auto;
      margin-bottom: 0; }
      .l-header__logo > img {
        width: 200px; } }

.l-header__burger-button,
.l-header__cart-button {
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.2rem;
  color: #68c0ba;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  transform: translateY(-50%); }
  @media (min-width: 768px) {
    .l-header__burger-button,
    .l-header__cart-button {
      display: none; } }
  .l-header__burger-button > i,
  .l-header__cart-button > i {
    display: block;
    font-size: 2.6rem; }
  .l-header__burger-button .m-badge,
  .l-header__cart-button .m-badge {
    position: absolute;
    top: -0.4rem;
    right: -0.4rem; }

@media (min-width: 768px) {
  .l-header__burger-button {
    display: block; } }

@media (min-width: 1024px) {
  .l-header__burger-button {
    display: none; } }

.l-header__cart-button {
  right: auto;
  left: 2rem;
  margin-top: 0; }
  .l-header__cart-button > i {
    color: #9a9a9a;
    font-size: 3.8rem; }

.l-header__sec-nav {
  position: absolute;
  z-index: 5001;
  display: none; }
  @media (min-width: 768px) {
    .l-header__sec-nav {
      top: 1rem;
      right: 7rem;
      display: block;
      transform: scale(0.95); } }
  @media (min-width: 1024px) {
    .l-header__sec-nav {
      top: 4.9rem;
      right: 1rem;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .l-header__sec-nav .m-nav-bar {
      padding-right: 0; } }
  .l-header__sec-nav .m-nav-bar > li {
    position: relative; }
    .l-header__sec-nav .m-nav-bar > li:last-child {
      display: none; }
      @media (min-width: 1024px) {
        .l-header__sec-nav .m-nav-bar > li:last-child {
          display: block; } }
    .l-header__sec-nav .m-nav-bar > li .m-badge {
      position: absolute;
      top: -0.4rem;
      left: -0.4rem;
      margin-top: -0.1rem;
      transform: translateX(100%); }
      @media (min-width: 768px) {
        .l-header__sec-nav .m-nav-bar > li .m-badge {
          left: 50%;
          margin-left: -1.6rem; } }
      @media (min-width: 1024px) {
        .l-header__sec-nav .m-nav-bar > li .m-badge {
          left: -0.4rem;
          margin-left: 0; } }
    .l-header__sec-nav .m-nav-bar > li .m-link i {
      margin-right: 0.4rem;
      font-size: 2.5rem; }
      .l-header__sec-nav .m-nav-bar > li .m-link i.ico-warenkorb {
        font-size: 2.8rem; }
  .l-header__sec-nav .ng-valid > .m-drop-down,
  .l-header__sec-nav .ng-invalid > .m-drop-down {
    border-color: #eaeaea;
    background: #eaeaea; }
  .l-header__sec-nav .ng-valid .m-drop-down__selected-option,
  .l-header__sec-nav .ng-invalid .m-drop-down__selected-option {
    border: 0.1rem solid #eaeaea; }

.l-header__main-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  border-top: 0.2rem solid #eaeaea; }
  @media (min-width: 1024px) {
    .l-header__main-nav {
      display: block; } }
  .l-header__main-nav > div {
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto; }
    .l-header__main-nav > div:after {
      content: " ";
      display: block;
      clear: both; }

@media (min-width: 1024px) {
  .touchevents .l-header {
    position: fixed;
    height: auto;
    padding-top: 1.4rem; }
  .touchevents .l-header__logo {
    height: 5.6rem; }
  .touchevents .l-header__sec-nav {
    top: 1.3rem;
    right: 7rem;
    display: block;
    transform: scale(0.95); }
    .touchevents .l-header__sec-nav .m-nav-bar > li:last-child {
      display: none; }
  .touchevents .l-header__burger-button {
    display: block; }
  .touchevents .l-header__main-nav {
    display: none !important; } }

@media (min-width: 768px) {
  .l-home .l-content__section--span-mobile {
    max-height: 31.5rem; } }

@media (min-width: 1024px) {
  .l-home .l-content__section--span-mobile {
    max-height: 56.2rem; } }

@media (min-width: 1024px) {
  .l-home__guarantee-teasers {
    margin-right: -1rem;
    margin-left: -1rem; } }

@media (min-width: 1024px) {
  .l-home__guarantee-teasers .m-guarantee-teaser {
    float: left;
    width: calc(100 / 3 * 1% - 2rem);
    margin-right: 1rem;
    margin-left: 1rem; } }

.l-landing .condensed > div {
  margin-bottom: 4.2rem; }

@media (min-width: 768px) {
  .l-login .m-box {
    min-height: 35rem; } }

@media (min-width: 768px) {
  .l-login .m-box > div > div {
    width: 75%;
    margin: 0 auto; } }

.l-login input {
  margin-bottom: 0.7rem;
  text-align: center; }
  .l-login input:last-of-type {
    margin-bottom: 1.4rem; }

.l-login .m-button {
  display: inline-block;
  width: 100%;
  line-height: 2.1rem; }

.l-login fieldset {
  width: 96%;
  margin-right: auto;
  margin-left: auto;
  text-align: left; }
  @media (min-width: 1024px) {
    .l-login fieldset {
      width: 85%; } }
  .l-login fieldset a {
    float: right;
    font-size: 1.3rem;
    font-weight: 400; }
  .l-login fieldset .m-checkbox + label {
    text-align: center; }

.l-login__reset-form .m-button {
  float: left;
  display: block;
  min-width: 0; }
  .l-login__reset-form .m-button:first-of-type {
    width: 22%;
    margin-right: 2%;
    margin-left: 0; }
    @media (min-width: 1024px) {
      .l-login__reset-form .m-button:first-of-type {
        width: 28%; } }
    .l-login__reset-form .m-button:first-of-type > span {
      display: none; }
      @media (min-width: 1024px) {
        .l-login__reset-form .m-button:first-of-type > span {
          display: inline; } }
    .l-login__reset-form .m-button:first-of-type > i {
      position: relative;
      top: 0.2rem; }
      @media (min-width: 1024px) {
        .l-login__reset-form .m-button:first-of-type > i {
          display: none; } }
  .l-login__reset-form .m-button:last-of-type {
    width: 76%;
    margin-right: 0;
    margin-left: 0; }
    @media (min-width: 1024px) {
      .l-login__reset-form .m-button:last-of-type {
        width: 70%; } }

.l-mobile-filter {
  padding-top: 7rem;
  padding-right: 1.8rem;
  padding-bottom: 2.8rem;
  padding-left: 1.8rem;
  font-size: 1.6rem; }
  @media (min-width: 768px) {
    .l-mobile-filter {
      display: none; } }
  .l-mobile-filter > header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    height: 5.6rem;
    padding-top: 0.7rem;
    padding-right: 4.2rem;
    padding-left: 1.8rem;
    background: #fff; }
    .l-mobile-filter > header > h4 {
      font-size: 1.5rem;
      line-height: 4.2rem;
      text-align: left;
      letter-spacing: 0.1rem; }
  .l-mobile-filter > strong {
    display: block; }
  .l-mobile-filter .m-drop-down {
    margin-bottom: 1.4rem; }
  .l-mobile-filter .m-button {
    margin-right: 0.4rem;
    vertical-align: top; }
    .l-mobile-filter .m-button i.ico-grid-3 {
      position: relative;
      top: 0.4rem;
      font-size: 3.2rem; }
    .l-mobile-filter .m-button i.ico-grid-4 {
      position: relative;
      top: -0.2rem;
      font-size: 4.4rem; }

.l-offcanvas-panel {
  position: fixed;
  z-index: 6000;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 40rem;
  max-width: 100%;
  background: #fff;
  transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateX(100%); }
  .l-offcanvas-panel:before {
    position: absolute;
    z-index: 6000;
    top: 0;
    right: 40rem;
    bottom: 0;
    left: -100vw;
    background: rgba(58, 58, 58, 0.9);
    content: '';
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    pointer-events: none;
    opacity: 0; }
  .l-offcanvas-panel.active {
    transform: translateX(0); }
    .l-offcanvas-panel.active:before {
      pointer-events: auto;
      opacity: 1; }

.l-offcanvas-panel--left {
  right: auto;
  left: 0;
  transform: translateX(-100%); }
  .l-offcanvas-panel--left:before {
    right: -100vw;
    left: 40rem; }

.l-offcanvas-panel--small {
  width: 32rem; }
  .l-offcanvas-panel--small:before {
    left: 32rem; }

.l-overlay {
  position: fixed;
  z-index: 7000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background: #fff; }
  .l-overlay.active {
    display: block;
    animation: fadeIn 0.3s; }

.l-overlay--medium {
  background: #eaeaea; }
  .l-overlay--medium .l-overlay__close-button {
    top: 0.7rem;
    border: none;
    color: #68c0ba;
    background: transparent; }
    .l-overlay--medium .l-overlay__close-button > i {
      font-size: 1.8rem; }

.l-overlay--transparent {
  background: rgba(58, 58, 58, 0.9); }

.l-overlay__close-button {
  position: absolute;
  z-index: 7001;
  top: 0;
  right: 0;
  width: 4.2rem;
  min-width: 0;
  height: 4.2rem;
  min-height: 0;
  padding-right: 0;
  padding-left: 0; }
  .l-overlay__close-button:hover {
    color: #fff;
    background: #48ada6; }
  .l-overlay__close-button > i {
    position: relative;
    top: -0.2rem;
    left: 0.1rem; }
    @media (min-width: 768px) {
      .l-overlay__close-button > i {
        top: 0; } }
    .l-overlay__close-button > i.ico-check, .l-overlay__close-button > i.l-cart-panel__message:before {
      font-size: 2.4rem;
      top: -0.4rem; }

.l-overlay__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto; }

.l-product {
  margin-top: 2.8rem;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .l-product:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .l-product {
      margin-top: 5.6rem; } }

.l-product__images {
  margin-bottom: 1.4rem;
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 1024px) {
    .l-product__images {
      margin-bottom: 0;
      width: 66.6666666667%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-product__images > div {
    position: relative;
    overflow: hidden; }
    .l-product__images > div:first-child {
      height: 30.8rem;
      margin-bottom: 1.4rem;
      background: #eaeaea; }
      @media (min-width: 568px) {
        .l-product__images > div:first-child {
          height: 56rem; } }
      @media (min-width: 768px) {
        .l-product__images > div:first-child {
          height: 75.6rem; } }
      @media (min-width: 1024px) {
        .l-product__images > div:first-child {
          height: 76rem;
          padding: 1.8rem; } }
    .l-product__images > div > img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      max-width: 95%;
      max-height: 95%;
      cursor: pointer;
      transform: translateX(-50%) translateY(-50%); }

.l-product__badge {
  position: absolute;
  top: 2.8rem;
  left: 0;
  width: 13rem;
  height: 4.9rem;
  color: #fff;
  background: #db701d; }
  .l-product__badge > span {
    position: absolute;
    top: 50%;
    right: 1.4rem;
    left: 1.2rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.68rem;
    text-align: center;
    transform: translateY(-50%); }

.l-product__thumbnails {
  min-height: 5.6rem; }
  .l-product__thumbnails div {
    position: relative;
    display: inline-block;
    width: 7rem;
    height: 7rem;
    border: 0.1rem solid #eaeaea;
    background: #eaeaea;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s; }
    @media (min-width: 1024px) {
      .l-product__thumbnails div {
        width: 8rem;
        height: 8rem;
        margin-right: 1.2rem; } }
    .l-product__thumbnails div:hover {
      border-color: #68c0ba; }
  .l-product__thumbnails img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 6rem;
    max-height: 6rem;
    margin-right: 0.4rem;
    vertical-align: top;
    transform: translateX(-50%) translateY(-50%); }
    @media (min-width: 1024px) {
      .l-product__thumbnails img {
        max-width: 7rem;
        max-height: 7rem; } }
  .l-product__thumbnails .m-button {
    width: 7rem;
    min-width: 0;
    height: 7rem;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    text-transform: none;
    white-space: normal;
    letter-spacing: 0;
    vertical-align: top; }
    @media (min-width: 1024px) {
      .l-product__thumbnails .m-button {
        width: 8rem;
        height: 8rem; } }

.l-product__details {
  text-align: center;
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 1024px) {
    .l-product__details {
      height: 76rem;
      width: 33.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-product__details .m-tabs {
    height: 100%;
    margin-bottom: 2.8rem;
    padding-bottom: 6.3rem; }
  .l-product__details .m-tabs--single-tab .m-tabs {
    padding-bottom: 0; }
  .l-product__details .m-tabs__tab {
    position: relative;
    height: 100%;
    min-height: 74.2rem;
    padding: 2.8rem;
    color: #fff;
    background: #646464;
    text-align: center; }
    @media (min-width: 768px) {
      .l-product__details .m-tabs__tab {
        min-height: 58.8rem; } }
    @media (min-width: 1024px) {
      .l-product__details .m-tabs__tab {
        min-height: 0; } }
    .l-product__details .m-tabs__tab > header {
      display: none; }
      @media (min-width: 1024px) {
        .l-product__details .m-tabs__tab > header {
          display: block; } }
    .l-product__details .m-tabs__tab .m-property-list {
      margin-bottom: 2.8rem; }
      @media (min-width: 768px) {
        .l-product__details .m-tabs__tab .m-property-list {
          float: left;
          width: 50%; } }
      @media (min-width: 1024px) {
        .l-product__details .m-tabs__tab .m-property-list {
          float: none;
          width: auto;
          margin-bottom: 0; } }
      @media (min-width: 768px) {
        .l-product__details .m-tabs__tab .m-property-list > li {
          margin-bottom: 0;
          text-align: left; } }
      @media (min-width: 1024px) {
        .l-product__details .m-tabs__tab .m-property-list > li {
          margin-bottom: 1.4rem;
          text-align: center; } }
      @media (min-width: 768px) {
        .l-product__details .m-tabs__tab .m-property-list > li > strong {
          display: inline-block; } }
      @media (min-width: 1024px) {
        .l-product__details .m-tabs__tab .m-property-list > li > strong {
          display: block; } }
    .l-product__details .m-tabs__tab footer {
      position: absolute;
      right: 2.8rem;
      bottom: 2.8rem;
      left: 2.8rem; }
      .l-product__details .m-tabs__tab footer:after {
        content: " ";
        display: block;
        clear: both; }
      .l-product__details .m-tabs__tab footer .m-price-tag {
        margin-bottom: 2.8rem; }
      .l-product__details .m-tabs__tab footer .m-feature-icon {
        float: left;
        padding-right: 0.6rem;
        padding-left: 0.6rem;
        width: 33.3333333333%;
        margin-top: 2.8rem;
        border-right: 0.2rem solid #9a9a9a; }
        @media (min-width: 1024px) {
          .l-product__details .m-tabs__tab footer .m-feature-icon {
            display: inline-block;
            margin-top: 5.6rem; } }
        .l-product__details .m-tabs__tab footer .m-feature-icon:last-child {
          border-right: none; }
    @media (min-width: 768px) {
      .l-product__details .m-tabs__tab > .m-separator {
        display: none; } }
    @media (min-width: 1024px) {
      .l-product__details .m-tabs__tab > .m-separator {
        display: block; } }
  .l-product__details .m-link {
    float: left;
    display: block;
    overflow: hidden;
    width: 50%;
    margin-bottom: 0.7rem;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .l-product__details .m-link:last-child {
      margin-right: 0; }

@media (min-width: 1024px) {
  .l-product__tabs {
    display: block;
    height: 76rem;
    margin-bottom: 2.8rem;
    background: #646464; } }

.l-product__artist {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1rem; }

.l-products {
  min-height: 80vh; }
  @media (min-width: 768px) {
    .l-products {
      margin-bottom: 11.2rem; } }

.l-products__toolbar-container {
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .l-products__toolbar-container:after {
    content: " ";
    display: block;
    clear: both; }
  .l-products__toolbar-container:last-of-type {
    margin-bottom: 8.4rem; }

.l-products__toolbar {
  position: relative;
  z-index: 3;
  display: none;
  margin-bottom: 0.2rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem; }
  .l-products__toolbar:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .l-products__toolbar {
      display: block; } }
  .l-products__toolbar:last-of-type {
    z-index: 2;
    margin-bottom: 2.8rem; }
  .l-products__toolbar > div {
    float: left;
    width: 20%;
    padding-right: 2rem; }
    .l-products__toolbar > div:last-child:not(:first-child) {
      padding-right: 0; }
    .l-products__toolbar > div.l-products__toolbar-pagination {
      float: right;
      width: 40%; }
      @media (min-width: 1024px) {
        .l-products__toolbar > div.l-products__toolbar-pagination {
          width: 20%; } }
    .l-products__toolbar > div .m-button--link {
      height: 3.5rem;
      margin-right: 0.4rem;
      font-size: 3.2rem;
      vertical-align: middle; }
      .l-products__toolbar > div .m-button--link.active {
        color: #9a9a9a; }
      .l-products__toolbar > div .m-button--link i.ico-grid-3 {
        position: relative;
        top: 0.6rem; }
      .l-products__toolbar > div .m-button--link i.ico-grid-4 {
        font-size: 4.4rem; }

.l-products__toolbar--bottom {
  display: block; }

@media (min-width: 1200px) {
  .l-products__tiles {
    margin-right: -2rem;
    margin-left: -2rem; } }

.l-products__tiles > div {
  position: relative;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .l-products__tiles > div:after {
    content: " ";
    display: block;
    clear: both; }
  .l-products__tiles > div:first-child {
    min-height: 20rem; }
  .l-products__tiles > div .m-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }

.l-products__tiles .m-product-tile,
.l-products__tiles .m-product-tile-skeleton {
  margin-bottom: 2rem;
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 568px) {
    .l-products__tiles .m-product-tile,
    .l-products__tiles .m-product-tile-skeleton {
      width: 50%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  @media (min-width: 1024px) {
    .l-products__tiles .m-product-tile,
    .l-products__tiles .m-product-tile-skeleton {
      width: 25%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

.l-products__tiles--scrolling.l-products__tiles--large > div .m-product-tile {
  width: 24.8rem; }
  @media (min-width: 1024px) {
    .l-products__tiles--scrolling.l-products__tiles--large > div .m-product-tile {
      width: 33.3333333333%; } }

.l-products__tiles--scrolling > div {
  overflow-x: auto;
  white-space: nowrap; }
  @media (min-width: 1024px) {
    .l-products__tiles--scrolling > div {
      overflow-x: visible;
      white-space: normal; } }
  .l-products__tiles--scrolling > div::-webkit-scrollbar {
    display: none; }
  .l-products__tiles--scrolling > div .m-product-tile {
    float: none;
    display: inline-block;
    width: 24.8rem; }
    @media (min-width: 1024px) {
      .l-products__tiles--scrolling > div .m-product-tile {
        float: left;
        display: block;
        width: 25%;
        white-space: normal; } }
    .l-products__tiles--scrolling > div .m-product-tile .m-product-tile__badge {
      white-space: normal; }

@media (min-width: 768px) {
  .l-products__tiles--large .m-product-tile {
    width: 33.3333333333%;
    float: left;
    padding-left: 0.8333333333%;
    padding-right: 0.8333333333%; } }

.l-products__event-inlet {
  width: 100%;
  float: left;
  padding-left: 0.8333333333%;
  padding-right: 0.8333333333%; }
  @media (min-width: 1024px) {
    .l-products__event-inlet {
      width: 50%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-products__event-inlet > div {
    width: 100%; }

@media (min-width: 768px) {
  .l-registration .m-box--stretched {
    height: 43.4rem; } }

@media (min-width: 1024px) {
  .l-registration .m-box--stretched {
    height: 35rem; } }

.l-registration .m-box--stretched .m-button {
  width: 100%;
  margin-bottom: 0.7rem; }
  @media (min-width: 1024px) {
    .l-registration .m-box--stretched .m-button {
      width: 48%;
      margin-right: 2%;
      margin-bottom: 0; } }
  .l-registration .m-box--stretched .m-button:last-of-type {
    margin-right: 0;
    margin-bottom: 0; }

.l-sharing-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5.6rem;
  margin-left: -2.73rem;
  transform: scale(0.65); }
  @media (min-width: 768px) {
    .l-sharing-overlay {
      margin-left: -4.2rem;
      transform: scale(1); } }
  .l-sharing-overlay > div {
    transform: translateY(-50%); }
  .l-sharing-overlay .m-social-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 8.4rem;
    height: 8.4rem;
    transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transform: translateX(0%) translateY(0%) scale(0.5) rotate(359deg); }
    .l-sharing-overlay .m-social-button:nth-of-type(1) {
      z-index: 1;
      cursor: default;
      transform: translateX(0%) translateY(0%) scale(0.8) rotate(0deg); }
      .l-sharing-overlay .m-social-button:nth-of-type(1):hover {
        border-color: #68c0ba;
        background: #68c0ba; }
    .l-sharing-overlay .m-social-button.transitioned:nth-of-type(2) {
      transform: translateX(-190%) translateY(65%) scale(1) rotate(0deg); }
    .l-sharing-overlay .m-social-button.transitioned:nth-of-type(3) {
      transition-delay: 0.2s;
      transform: translateX(-75%) translateY(165%) scale(1) rotate(0deg); }
    .l-sharing-overlay .m-social-button.transitioned:nth-of-type(4) {
      transition-delay: 0.4s;
      transform: translateX(75%) translateY(165%) scale(1) rotate(0deg); }
    .l-sharing-overlay .m-social-button.transitioned:nth-of-type(5) {
      transition-delay: 0.6s;
      transform: translateX(190%) translateY(65%) scale(1) rotate(0deg); }
    .l-sharing-overlay .m-social-button > i {
      top: 0.7rem;
      left: 0.1rem;
      font-size: 3.6rem; }

.l-skeleton {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%; }
  .l-skeleton .js-skeleton {
    z-index: 1;
    display: block;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
  .l-skeleton .js-skeleton-replacement {
    opacity: 0; }

.l-standard-content h2,
.l-standard-content .h2,
.l-standard-content h3,
.l-standard-content .h3,
.l-standard-content .m-form fieldset legend, .m-form fieldset
.l-standard-content legend,
.l-standard-content h4,
.l-standard-content .h4,
.l-standard-content h5,
.l-standard-content .h5 {
  text-align: left; }

.l-standard-content .container h2,
.l-standard-content .container .h2,
.l-standard-content .container h3,
.l-standard-content .container .h3,
.l-standard-content .container .m-form fieldset legend, .m-form fieldset
.l-standard-content .container legend,
.l-standard-content .container h4,
.l-standard-content .container .h4,
.l-standard-content .container h5,
.l-standard-content .container .h5 {
  text-align: center; }

.l-standard-content .container div {
  text-align: center; }

.l-standard-content .m-box h3,
.l-standard-content .m-box .h3,
.l-standard-content .m-box .m-form fieldset legend, .m-form fieldset
.l-standard-content .m-box legend {
  text-align: center; }

.l-standard-content .l-content__section > div .condensed {
  text-align: left; }
  .l-standard-content .l-content__section > div .condensed:after {
    content: " ";
    display: block;
    clear: both; }
  .l-standard-content .l-content__section > div .condensed p img {
    float: left;
    max-width: 100%;
    margin-right: 2rem;
    margin-bottom: 0.7rem; }
  .l-standard-content .l-content__section > div .condensed > .container {
    padding-right: 1rem;
    padding-left: 1rem; }
    @media (min-width: 768px) {
      .l-standard-content .l-content__section > div .condensed > .container {
        padding-right: 0;
        padding-left: 0; } }
    .l-standard-content .l-content__section > div .condensed > .container > div > img {
      width: 100%; }
    .l-standard-content .l-content__section > div .condensed > .container > div small {
      display: block;
      margin-bottom: 2.8rem;
      font-size: 1.6rem;
      text-align: center; }
      @media (min-width: 768px) {
        .l-standard-content .l-content__section > div .condensed > .container > div small {
          margin-bottom: 0; } }

.l-standard-content .l-content__section--medium h2,
.l-standard-content .l-content__section--medium .h2,
.l-standard-content .l-content__section--medium h3,
.l-standard-content .l-content__section--medium .h3,
.l-standard-content .l-content__section--medium .m-form fieldset legend, .m-form fieldset
.l-standard-content .l-content__section--medium legend,
.l-standard-content .l-content__section--medium h4,
.l-standard-content .l-content__section--medium .h4,
.l-standard-content .l-content__section--medium h5,
.l-standard-content .l-content__section--medium .h5 {
  text-align: center; }

.l-stage {
  display: block; }
  .l-stage .l-carousel {
    overflow: hidden;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-bottom: 0; }
    .l-stage .l-carousel nav {
      margin-top: 4.2rem;
      vertical-align: baseline; }
      @media (min-width: 768px) {
        .l-stage .l-carousel nav {
          margin-top: 2.8rem; } }
  .l-stage .l-carousel__slide {
    padding-right: 0;
    padding-left: 0; }
  .l-stage .l-carousel__nav-button {
    display: inline-block; }
  .l-stage .m-dot-indicator {
    display: block; }
  .l-stage .m-dot-indicator__dot {
    background: #eaeaea; }
    .l-stage .m-dot-indicator__dot.active {
      background: #db701d; }

.l-stage__slide {
  position: relative;
  vertical-align: top;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  .l-stage__slide:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    .l-stage__slide {
      height: 27.3rem; } }
  @media (min-width: 1024px) {
    .l-stage__slide {
      height: 49rem; } }

.l-stage__slide-info {
  position: relative;
  height: 50%;
  min-height: 27.9rem;
  color: #fff;
  background: #646464;
  text-align: center; }
  @media (min-width: 768px) {
    .l-stage__slide-info {
      height: 100%;
      min-height: 0;
      width: 33.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  @media (min-width: 1024px) {
    .l-stage__slide-info {
      width: 25%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-stage__slide-info > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    margin-right: 0;
    margin-left: 0;
    padding-top: 2.8rem;
    padding-bottom: 3.5rem;
    transform: translateX(-50%) translateY(-50%); }
    .l-stage__slide-info > div h2 {
      font-size: 2rem;
      line-height: 2.8rem; }
    .l-stage__slide-info > div .h--underlined {
      margin-bottom: 1.4rem;
      font-size: 1.3rem;
      font-weight: 400; }
      @media (min-width: 768px) {
        .l-stage__slide-info > div .h--underlined {
          font-size: 1.6rem; } }

.l-stage__slide-info--with-catalog {
  min-height: 27.9rem; }
  @media (min-width: 768px) {
    .l-stage__slide-info--with-catalog {
      min-height: 0; } }

.l-stage__slide-image {
  height: auto;
  background-position: center;
  background-size: cover; }
  @media (min-width: 768px) {
    .l-stage__slide-image {
      float: right !important;
      height: 100%;
      width: 66.6666666667%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; }
      .l-stage__slide-image:before {
        display: none;
        padding-top: 0; } }
  @media (min-width: 1024px) {
    .l-stage__slide-image {
      width: 75%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  .l-stage__slide-image:before {
    display: block;
    padding-top: 56.25%;
    content: ''; }
  .l-stage__slide-image > img {
    width: 100%; }

.l-stage__catalog-image {
  display: none; }
  @media (min-width: 768px) {
    .l-stage__catalog-image {
      position: absolute;
      top: 50%;
      right: 2.4rem;
      display: block;
      transform: translateY(-50%); } }
  .l-stage__catalog-image > img {
    max-height: 19.6rem; }
    @media (min-width: 1024px) {
      .l-stage__catalog-image > img {
        max-height: 33.6rem; } }

.l-stage__mobile-catalog-image {
  max-height: 14rem;
  margin-top: -11.2rem; }
  @media (min-width: 768px) {
    .l-stage__mobile-catalog-image {
      display: none; } }

.l-sticky-header {
  position: fixed;
  z-index: 4999;
  top: -8rem;
  right: 0;
  left: 0;
  display: none;
  overflow: hidden;
  height: 8rem;
  padding: 2rem;
  background: #fff;
  font-size: 1.6rem;
  transition: top 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  box-shadow: 0 0 0.3rem rgba(58, 58, 58, 0.3); }
  @media (min-width: 768px) {
    .l-sticky-header {
      display: block; } }
  .l-sticky-header.active {
    top: 0; }
  .l-sticky-header > div {
    position: absolute;
    top: 50%;
    right: 2rem;
    left: 2rem;
    max-height: 100%;
    transform: translateY(-50%); }
    .l-sticky-header > div:after {
      content: " ";
      display: block;
      clear: both; }
  .l-sticky-header .m-abc-nav {
    max-width: 120rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .l-sticky-header .l-products__toolbar {
    max-width: 120rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0; }
    @media (min-width: 1024px) {
      .l-sticky-header .l-products__toolbar {
        padding: 0 1rem; } }

.l-sticky-header--overflowing {
  overflow: visible; }

.l-sticky-header__left-tool {
  float: left; }
  .l-sticky-header__left-tool .m-signet {
    position: relative;
    top: -1rem; }

.l-sticky-header__right-tool {
  float: right;
  margin-left: 1.2rem; }

.l-sticky-header__info {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 0.3rem;
  line-height: 2.1rem;
  text-align: center;
  transform: translateX(-50%) translateY(-50%); }
  .l-sticky-header__info > strong {
    margin-bottom: 0; }
  .l-sticky-header__info > small {
    font-weight: 400; }

.l-storage-error-overlay {
  max-width: 64rem;
  margin: 5.6rem auto;
  padding: 0 1.2rem;
  text-align: center; }
  .l-storage-error-overlay > img {
    margin-bottom: 2.8rem; }
  .l-storage-error-overlay > p {
    margin-bottom: 2.8rem; }

.l-wishlist .container {
  padding-right: 1rem;
  padding-left: 1rem; }
  @media (min-width: 768px) {
    .l-wishlist .container {
      padding-right: 0;
      padding-left: 0; } }

.l-wishlist .m-product-tile {
  margin-bottom: 1.4rem; }
  @media (min-width: 568px) {
    .l-wishlist .m-product-tile {
      width: 50%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  @media (min-width: 1024px) {
    .l-wishlist .m-product-tile {
      width: 33.3333333333%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }
  @media (min-width: 1200px) {
    .l-wishlist .m-product-tile {
      width: 25%;
      float: left;
      padding-left: 0.8333333333%;
      padding-right: 0.8333333333%; } }

.popup-area {
  position: fixed;
  top: 90px;
  left: 12px;
  right: 12px;
  background: #eaeaea;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0px #9a9a9a;
  z-index: 1000;
  padding: 30px;
  text-align: center;
  display: none; }
  @media (min-width: 568px) {
    .popup-area {
      top: 25%;
      left: 0;
      width: 100%;
      max-width: 450px;
      max-height: 100%; } }
  .popup-area .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer; }
  .popup-area .popup-image {
    width: 100%;
    margin-bottom: 30px; }
  .popup-area .popup-headline {
    font-size: 28px; }
    .popup-area .popup-headline:after {
      background-color: #9a9a9a; }
  .popup-area .popup-text {
    margin-bottom: 30px; }
  .popup-area .popup-button {
    background-color: #db701d;
    border-color: #db701d; }
    .popup-area .popup-button:hover {
      color: #db701d;
      border: 2px solid #db701d;
      background-color: transparent; }
